/** @jsxImportSource theme-ui */
import { Flex, Heading } from "theme-ui";
import ContextCards from "./ContextCards";
import Spacer from "../../../common/Spacer";
import Timeline from "../../common/Timeline";
import HeadingAndBody from "../../common/HeadingAndBody";
import { contextSections } from "../../content/shopifyBFCM";
import DesignRequirements from "./DesignRequirements";
import ShippingFreights from "./ShippingFreights";

export default function Context() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Context
      </Heading>
      <Spacer height="45px" />
      <ContextCards />
      <Spacer height="104px" />
      <Timeline
        title="Project timeline"
        sections={[
          {
            heading: "Research",
            gridColumnStart: 1,
            details: [
              {
                items: ["Audit", "Project Intake and alignment"],
              },
            ],
          },
          {
            heading: "Design",
            gridColumnStart: 2,
            gridColumnEnd: 4,
            details: [
              {
                items: ["Explorations", "Design System", "Admin Integration"],
              },
              {
                items: ["Feedback"],
              },
            ],
          },
          {
            heading: "Launch",
            gridColumnStart: 4,
            details: [
              {
                items: ["Retrospective", "Recognition"],
              },
            ],
          },
        ]}
      />
      <Spacer height={["50px", "100px"]} />
      <HeadingAndBody {...contextSections[0]} />
      <Spacer height={["50px", "130px"]} />
      <HeadingAndBody {...contextSections[1]} />
      <Spacer height={["50px", "100px"]} />
      <DesignRequirements />
      <Spacer height={["50px", "77px"]} />
      <HeadingAndBody {...contextSections[2]} />
      <Spacer height={["50px", "38px"]} />
      <HeadingAndBody {...contextSections[3]} />
      <Spacer height={["50px", "38px"]} />
      <HeadingAndBody {...contextSections[4]} />
      <Spacer height={["50px", "38px"]} />
      <HeadingAndBody {...contextSections[5]} noGap />
      <Spacer height={["50px", "38px"]} />
      <HeadingAndBody {...contextSections[6]} />
      <Spacer height={["50px", "38px"]} />
      <HeadingAndBody {...contextSections[7]} />
      <Spacer height={["50px", "38px"]} />
      <HeadingAndBody {...contextSections[8]} />
      <Spacer height={["50px", "38px"]} />
      <ShippingFreights />
      <Spacer height={["50px", "38px"]} />
      <HeadingAndBody {...contextSections[9]} />
      <Spacer height={["50px", "38px"]} />
      <HeadingAndBody {...contextSections[10]} />
    </Flex>
  );
}
