import Spacer from "../components/common/Spacer";
import { Experience } from "../components/about/Experience";
import Hero from "../components/about/Hero";
import { Box } from "theme-ui";

export default function About() {
  return (
    <Box sx={{ pr: [0, null, null, "80px"] }}>
      <Hero />
      <Experience />
      <Spacer height={["100px", "140px"]} />
    </Box>
  );
}
