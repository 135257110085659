import sheridan from "../../assets/about/sheridan.png";

export const about = [
  `Throughout my career, I've had the opportunity to work on a variety of projects across different industries and disciplines, including supply chain management, data analysis, financial technology,  e-commerce, and digital health and wellness.`,
  `I also have diverse experience working across a range of settings, including traditional agencies, start-ups, and global corporations, each offering unique challenges and opportunities for growth.`,
  `When I'm not designing, I love to stay active and exercise regularly. I'm also a huge foodie and love travelling! ✈️ 🍣.  If you have an opportunity for me that you would like to discuss feel free to contact me!`,
];

export const experienceHeading = "Experience";

export const experience = [
  {
    titleDateLoc: {
      title: "Shopify",
      date: "May 2022 - May 2023",
      loc: "Toronto, Canada",
    },
    description: [
      {
        title: "Core - Optimize",
        body: `I played a critical role in the 
        <a href="https://investors.shopify.com/news-and-events/press-releases/news-details/2022/Shopify-Merchants-Set-New-Black-Friday-Cyber-Monday-Record-with-7.5-Billion-in-Sales/default.aspx" target="_blank">
        Shopify Black Friday Cyber Monday 2022 annual globe data story website project
        </a> 
        and the Real Time Dashboard on the optimize team. 
        I collaborated closely with developers to design and develop a rapid prototype for both projects. 
        It was  a challenging, and high visibility project. 
        I was thrilled to have contributed to its success. 
        I also worked on an audit and made recommendations for the mobile analytics components used in the admin.`,
      },
      {
        title: "Logistics - Inbounds",
        body: `Led the design of the Inbounds section of the Unified Migration project of merchants to 
        the new internal system. Worked closely with developers and content designers to establish a strategy 
        around messaging and guidance for migrating users.`,
      },
    ],
  },
  {
    titleDateLoc: {
      title: "Lumber",
      date: "May 2021 - May 2022",
      loc: "New York, United States",
    },
    description: [
      {
        body: `Co-lead the design team and championed multiple client projects. 
        One of which was the complete end-to-end redesign of the <a href="/goodehealth">Goodehealth Dashboard</a>. 
        I was responsible for presenting design concepts to clients. 
        Maintaining effective communication with developers and business stakeholders was a key part of my role.`,
      },
    ],
  },
  {
    titleDateLoc: {
      title: "MyPalate Inc",
      date: "May 2021 - Jul 2021",
      loc: "Hamilton, Canada",
    },
    description: [
      {
        body: `Led the effort to create a design system that could be used across multiple platforms (web and mobile) 
        and ensured accessibility was a top priority. 
        I also simplified internal documents to make it easier for developers to understand and implement our designs.`,
      },
    ],
  },
];

export const achievementsHeading = "Volunteer and Achievements";

export const achievements = [
  {
    titleDateLoc: {
      title: "Sheridan College Interaction Design Association",
      loc: "Oakville, Canada",
      icon: sheridan,
    },
    description: [
      {
        title: "Marketing",
        date: "September 2021 - February 2022",
        body: `As part of a student design team, I collaborated with organizers to design various communication materials for events.`,
      },
      {
        title: "Web Developer",
        date: "February 2021 - April 2021",
        body: `Co-led the design and development of the 2021 IXDA Yearbook Web Experience, using the React framework. In addition to my design work, I also hosted development workshops to help the team build their skills.`,
      },
    ],
  },
  {
    titleDateLoc: {
      title: "Adobe Creative Jam x Activision",
    },
    description: [
      {
        title: "Remote",
        date: "May 2020",
        body: `Awarded Honorable Mention placing top 20 out of 150+ teams. Created a prototype for a mobile application aimed at encouraging children to explore the world of gaming.`,
      },
    ],
  },
];
