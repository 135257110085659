/** @jsxImportSource theme-ui */
import { Box, Flex, IconButton, Image, ThemeProvider } from "theme-ui";
import { theme } from "./theme";
import Footer from "./components/common/Footer";
import Nav from "./components/common/Nav";
import { Outlet, useLocation } from "react-router-dom";
import {
  Fragment,
  createContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { ReactComponent as X } from "./assets/x.svg";
import { ReactComponent as ChevronLeft } from "./assets/chevron-left.svg";
import { fadeIn } from "./utils/animations";
import { ROUTES } from "./utils/constants";

const Wrapper = ({ children }) => {
  const location = useLocation();
  const size = useWindowSize();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
    const elementsToFadeIn = document.querySelectorAll(".fadein");
    const observerOptions = {
      root: null,
      rootMargin:
        (size.width !== null && size.width < 1024) ||
        location.pathname === "/about"
          ? "0px 0px -100px 0px"
          : "0px 0px -350px 0px",
      threshold: 0,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.transitionDelay = `${entry.target.dataset.delay}s`;
          entry.target.classList.add("loaded");
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);
    elementsToFadeIn.forEach((el) => observer.observe(el));
  }, [location.pathname, size.width]);

  useEffect(() => {
    const currentRoute = Object.values(ROUTES).find(
      (route) => route.path === location.pathname
    );
    if (currentRoute?.title) {
      document.title = currentRoute.title;
    }
  }, [location.pathname]);

  return children;
};

export const ModalImageContext = createContext(null);

export default function Layout() {
  const [modalImage, setModalImage] = useState(null);
  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.key === "Escape" && modalImage) {
        setModalImage(null);
      }
    };
    document.addEventListener("keydown", handleKeyUp);
    return () => document.removeEventListener("keydown", handleKeyUp);
  }, [modalImage]);
  return (
    <ThemeProvider theme={theme}>
      <ModalImageContext.Provider value={setModalImage}>
        <Wrapper>
          <Nav />
          <Outlet />
          <Footer />
          {modalImage && (
            <ImageModal modalImage={modalImage} setModalImage={setModalImage} />
          )}
        </Wrapper>
      </ModalImageContext.Provider>
    </ThemeProvider>
  );
}

function ImageModal({ modalImage, setModalImage }) {
  const [gallery, setGallery] = useState([]);
  const [selected, setSelected] = useState(null);
  const [translateX, setTranslateX] = useState("0px");
  const [opacity, setOpacity] = useState(1);
  const TRANSITION_TIME = 175;
  const SLIDE_AMOUNT = 15;
  useEffect(() => {
    const { group } = modalImage;
    if (group) {
      const groupedImgs = [
        ...document.querySelectorAll(`img[data-group="${modalImage.group}"]`),
      ];
      setGallery(groupedImgs.map((img) => img.getAttribute("src")));
    }
  }, [modalImage]);

  useEffect(() => {
    setSelected(gallery.findIndex((src) => modalImage.src === src));
  }, [gallery, modalImage.src]);

  function updateSelected({ index, direction }) {
    const toRight = direction === "right";
    setTranslateX(toRight ? `-${SLIDE_AMOUNT}px` : `${SLIDE_AMOUNT}px`);
    setOpacity(0);
    setTimeout(() => {
      setTranslateX(toRight ? `${SLIDE_AMOUNT}px` : `-${SLIDE_AMOUNT}px`);
      setSelected(index);
      setTimeout(() => {
        setTranslateX("0px");
        setOpacity(1);
      }, TRANSITION_TIME);
    }, TRANSITION_TIME);
  }

  return (
    <Flex
      sx={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        backgroundColor: "rgba(217, 217, 217, 0.80)",
        justifyContent: "center",
        alignItems: "center",
        p: ["20px", "100px"],
        animation: `${fadeIn} 0.25s ease`,
      }}
    >
      <IconButton
        onClick={() => setModalImage(null)}
        sx={{
          width: ["45px", "57px"],
          height: ["45px", "57px"],
          position: "absolute",
          top: ["20px", "43px"],
          right: ["20px", "73px"],
          cursor: "pointer",
          bg: "transparent",
          p: 0,
        }}
      >
        <X />
      </IconButton>
      {gallery.length > 0 && (
        <Fragment>
          <IconButton
            onClick={() =>
              updateSelected({
                index: selected - 1 < 0 ? gallery.length - 1 : selected - 1,
                direction: "left",
              })
            }
            sx={{
              position: "absolute",
              left: ["-5px", "75px"],
              top: "50%",
              transform: "translateY(-50%)",
              width: ["45px", "57px"],
              height: ["45px", "57px"],
              "svg path": { fill: "none !important" },
              bg: "transparent",
              p: 0,
            }}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={() =>
              updateSelected({
                index: selected + 1 >= gallery.length ? 0 : selected + 1,
                direction: "right",
              })
            }
            sx={{
              position: "absolute",
              right: ["-5px", "75px"],
              top: "50%",
              transform: "translateY(-50%) rotate(180deg)",
              width: ["45px", "57px"],
              height: ["45px", "57px"],
              "svg path": { fill: "none !important" },
              bg: "transparent",
              p: 0,
            }}
          >
            <ChevronLeft />
          </IconButton>
        </Fragment>
      )}
      <Image
        onClick={() => setModalImage(null)}
        src={
          modalImage.group && gallery.length && selected !== null
            ? gallery[selected]
            : modalImage.src
        }
        sx={{
          width: "auto",
          height: "auto",
          maxWidth: "90%",
          maxHeight: "100%",
          objectFit: "contain",
          cursor: "pointer",
          opacity,
          transform: `translateX(${translateX})`,
          transition: `opacity ${TRANSITION_TIME / 1000}s ease, transform ${
            TRANSITION_TIME / 1000
          }s ease`,
        }}
      />
      <Box
        onClick={() => setModalImage(null)}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          zIndex: -1,
        }}
      />
    </Flex>
  );
}
