/** @jsxImportSource theme-ui */
import { Divider, Flex, Heading, Image, Text } from "theme-ui";
import { insights } from "../../content/trac";
import Spacer from "../../../common/Spacer";
import { ReactComponent as User } from "../../../../assets/trac/user.svg";
export default function Insights() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, mb: "32px" }}>
        {insights.heading}
      </Heading>
      <Spacer height={["10px", "76px"]} />
      <Flex
        sx={{
          gap: "50px",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {insights.items.map(({ icon, heading, body }, i) => (
          <Flex
            className="outlined fadein"
            data-delay={(i / 3).toFixed(2)}
            key={heading}
            sx={{
              flexDirection: "column",
              p: "41px 18px 43px",
              maxWidth: "460px",
            }}
          >
            <Flex
              sx={{
                gap: "20px",
                alignItems: "center",
                pl: "20px",
                pr: "10px",
              }}
            >
              <Image src={icon} width="40" />
              <Text
                sx={{ fontSize: 2, fontWeight: "semibold", lineHeight: "173%" }}
              >
                {heading}
              </Text>
            </Flex>
            <Divider
              color="#F3F3F3"
              margin="26px auto 20px"
              sx={{ width: "93%", borderBottom: "3px solid" }}
            />
            <Flex
              sx={{
                gap: "20px",
                alignItems: "center",
                svg: { minWidth: "40px", height: "auto" },
              }}
            >
              <User />
              <Text sx={{ fontWeight: "semibold" }}>{body}</Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
