/** @jsxImportSource theme-ui */
import { Box, Button, Flex, Heading, IconButton, Link, Text } from "theme-ui";
import { ReactComponent as Coffee } from "../../assets/coffee.svg";
import { ReactComponent as Resume } from "../../assets/resume.svg";
import { ReactComponent as Linkedin } from "../../assets/linkedin.svg";
import { ReactComponent as Mail } from "../../assets/mail.svg";
import Spacer from "../common/Spacer";
import { LINKS } from "../../utils/constants";
import { coffee, fadeIn, slideUpReg } from "../../utils/animations";

export default function Hero() {
  return (
    <Flex
      sx={{
        height: [
          "auto",
          "calc(100vh - var(--top-margin) - var(--nav-height) - 200px - var(--home-hero-work-spacer))",
        ],
        maxHeight: "980px",
        minHeight: "340px",
        flexDirection: "column",
        justifyContent: "center",
        pt: ["50px", 0],
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          gap: "8px",
          animation: `${fadeIn} 1.3s cubic-bezier(0.24, 0.74, 0.42, 0.98)`,
          animationFillMode: "both",
          animationDelay: "0.4s",
          svg: {
            overflow: "visible",
            ".steam": {
              animation: `${coffee} 2s linear infinite`,
              opacity: 0,
            },
            path: {
              "&:nth-of-type(3)": {
                animationDelay: "-0.7s",
              },
              "&:nth-of-type(5)": {
                animationDelay: "-1.3s",
              },
            },
          },
        }}
      >
        <Text sx={{ fontSize: 2, lineHeight: "normal" }}>Hi, I'm Derman!</Text>
        <Coffee />
      </Flex>
      <Spacer height="19px" />
      <Box sx={{ overflow: "hidden" }}>
        <Heading
          sx={{
            fontSize: 5,
            fontWeight: "semibold",
            maxWidth: "1100px",
            lineHeight: "156%",
            strong: { fontWeight: "bold" },
            animation: `${slideUpReg} 1s cubic-bezier(0.24, 0.74, 0.42, 0.98)`,
            animationFillMode: "both",
            animationDelay: "1s",
            color: "text",
          }}
        >
          <strong>A Toronto based Product Designer</strong> with a passion for
          designing elegant and{" "}
          <strong>intuitive data driven experiences.</strong>
        </Heading>
      </Box>
      <Spacer height="35px" />
      <Flex
        sx={{
          gap: ["15px", "30px"],
          animation: `${fadeIn} 1.3s cubic-bezier(0.24, 0.74, 0.42, 0.98)`,
          animationFillMode: "both",
          animationDelay: "2s",
        }}
      >
        <Button
          as={Link}
          href={LINKS.RESUME}
          target="_blank"
          sx={{
            lineHeight: "160%",
            minWidth: "150px",
            svg: {
              mr: "7px",
              width: "14px",
              height: "auto",
            },
          }}
        >
          <Resume /> Resume
        </Button>
        <Flex sx={{ gap: "9px" }}>
          <IconButton as={Link} href={LINKS.LINKEDIN} target="__blank">
            <Linkedin />
          </IconButton>
          <IconButton as={Link} href={LINKS.EMAIL} variant="iconStroke">
            <Mail />
          </IconButton>
        </Flex>
      </Flex>
    </Flex>
  );
}
