/** @jsxImportSource theme-ui */
import { Flex, Heading, Text } from "theme-ui";
import { keySolElems } from "../../content/trac";
import { ReactComponent as Key } from "../../../../assets/trac/key.svg";
export default function KeySolutionElements() {
  return (
    <Flex sx={{ flexDirection: "column", gap: "44px" }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {keySolElems.heading}
      </Heading>
      <Flex sx={{ gap: "51px", justifyContent: "center", flexWrap: "wrap" }}>
        {keySolElems.items.map((item, i) => (
          <Flex
            key={item}
            className="outlined fadein"
            data-delay={(i / 3).toFixed(2)}
            sx={{
              flexDirection: "row",
              width: ["100%", null, "auto"],
              height: "auto",
              py: "36px",
              px: ["25px", "53px"],
              flex: [null, null, "1 1 0"],
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              svg: {
                minWidth: "40px",
              },
            }}
          >
            <Key />
            <Text
              sx={{
                fontSize: 3,
                fontWeight: "bold",
                lineHeight: "163%",
                ...(i === 0 && { maxWidth: "274px" }),
              }}
            >
              {item}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
