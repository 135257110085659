/** @jsxImportSource theme-ui */
import { Flex, Heading, Image, Text } from "theme-ui";
import role from "../../../../assets/goodehealth/myrole.png";
import { myRole } from "../../content/goodehealth";

export default function MyRole() {
  return (
    <Flex sx={{ gap: "58px", flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {myRole.heading}
      </Heading>
      <Flex
        sx={{
          gap: "20px",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Text
          className="fadein"
          sx={{
            fontWeight: "semibold",
            fontSize: 3,
            lineHeight: "163%",
            maxWidth: "515px",
          }}
        >
          {myRole.body}
        </Text>
        <Image className="fadein" src={role} sx={{ maxWidth: "837px" }} />
      </Flex>
    </Flex>
  );
}
