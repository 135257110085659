/** @jsxImportSource theme-ui */
import { Fragment } from "react";
import { Flex, Text } from "theme-ui";

export function Description({ description, className, alt = false, ...props }) {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: "20px",
        background: ["rgba(0,0,0,0.03)", "none"],
        borderRadius: ["10px", 0],
        maxWidth: "1066px",
      }}
      className={className}
      {...props}
    >
      {description.map((item) => {
        const { title, date, body } = item;
        return (
          <Flex
            key={title + date}
            sx={{
              flexDirection: "column",
              gap: "15px",
            }}
          >
            {alt ? (
              <Flex
                sx={{
                  justifyContent: "space-between",
                  flexDirection: ["column", "row"],
                }}
              >
                {title && (
                  <Text
                    className="title"
                    sx={{ fontSize: 3, fontWeight: "bold" }}
                  >
                    {title}
                  </Text>
                )}
                {date && (
                  <Text sx={{ fontSize: 3, fontWeight: "bold" }}>{date}</Text>
                )}
              </Flex>
            ) : (
              <Fragment>
                {title && (
                  <Text
                    as="strong"
                    sx={{
                      fontSize: "1.8rem",
                      fontWeight: "bold",
                      lineHeight: "normal",
                    }}
                  >
                    {title}
                  </Text>
                )}
                {date && (
                  <Text variant="caption" sx={{ color: "text" }}>
                    {date}
                  </Text>
                )}
              </Fragment>
            )}
            {body && (
              <Text
                dangerouslySetInnerHTML={{ __html: body }}
                sx={{
                  fontSize: 3,
                  lineHeight: "166%",
                  a: {
                    color: "accent",
                  },
                  fontWeight: "bold",
                }}
              />
            )}
          </Flex>
        );
      })}
    </Flex>
  );
}
