/** @jsxImportSource theme-ui */
import { Divider, Flex, Heading } from "theme-ui";
import Interviews from "./Interviews";
import Spacer from "../../../common/Spacer";
import Insights from "./Insights";

export default function Research() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Research
      </Heading>
      <Divider className="fadein" margin={["55px 0", "55px 0 100px"]} />
      <Interviews />
      <Spacer height="76px" />
      <Insights />
    </Flex>
  );
}
