/** @jsxImportSource theme-ui */
import { Button, Divider, Flex, Text } from "theme-ui";
import { Link as RRDLink } from "react-router-dom";

export default function Footer({ links }) {
  return (
    <Flex sx={{ flexDirection: "column", mt: "110px" }}>
      <Text
        className="fadein"
        sx={{
          fontSize: 5,
          fontWeight: "bold",
          lineHeight: "40px",
          color: "strong",
        }}
      >
        Fin.
      </Text>
      <Divider className="fadein" margin="25px 0" />
      <Flex
        className="fadein"
        sx={{ gap: ["10px", "34px"], alignItems: "center" }}
      >
        <Text>Other Projects</Text>
        {links.map((link) => (
          <Button variant="filled" key={link.url} as={RRDLink} to={link.url}>
            {link.text}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
}
