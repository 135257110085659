/** @jsxImportSource theme-ui */
import { Divider, Flex, Heading } from "theme-ui";
import InfoImages from "../../common/InfoImages";
import { infoImages } from "../../content/trac";

export default function Solution() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Solution
      </Heading>
      <Divider className="fadein" margin={["55px 0", "55px 0 118px"]} />
      <InfoImages
        info={infoImages}
        imagePx="200px"
        imagePy="175px"
        textMaxWidth="635px"
        maxHeight="600px"
        sx={{ ".outlined": { maxWidth: "697px" } }}
      />
    </Flex>
  );
}
