/** @jsxImportSource theme-ui */

import { Flex, Grid, Image, Link, Text } from "theme-ui";
import globe from "../../../../assets/shopifyBFCM/globe.png";
import ipad from "../../../../assets/shopifyBFCM/ipadSmall.png";
import { ReactComponent as ExternalLink } from "../../../../assets/externalLink.svg";
import Counter from "../../common/Counter";

export default function ContextCards() {
  return (
    <Grid
      gap="30px"
      sx={{
        gridTemplateColumns: ["auto", "2fr 1fr 1fr"],
      }}
    >
      <Flex
        className="card fadein"
        sx={{
          p: ["20px", 0],
          pl: [null, "42px"],
          span: { fontWeight: "semibold" },
          position: "relative",
        }}
      >
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            gap: "28px",
            zIndex: 1,
          }}
        >
          <Text
            sx={{
              fontSize: [4, 5],
              lineHeight: "128%",
              maxWidth: "347px",
              color: "strong",
            }}
          >
            Black Friday Cyber Monday is massive for e-commerce.
          </Text>
          <Text
            sx={{
              fontSize: [3, "2rem"],
              lineHeight: "150%",
              maxWidth: "290px",
            }}
          >
            Shopify's Merchants generate billions in sales during one of the
            biggest yearly commerce events.
          </Text>
        </Flex>
        <Image
          className="fadein"
          data-delay="0.5"
          src={globe}
          sx={{
            position: "absolute",
            top: ["-20px", "-40px"],
            right: 0,
            width: "55%",
            height: "110%",
            objectFit: "cover",
            objectPosition: "left top",
            opacity: [0.7, 1],
          }}
        />
      </Flex>
      <Flex
        className="card fadein"
        data-delay="0.25"
        sx={{
          flexDirection: "column",
          p: ["60px 20px 20px", "78px 40px 57px 40px"],
          textAlign: "right",
          justifyContent: "space-between",
          position: "relative",
          alignItems: "flex-end",
          "&.loaded .counter": { animationPlayState: "running" },
        }}
      >
        <Link
          href="https://investors.shopify.com/news-and-events/press-releases/news-details/2022/Shopify-Merchants-Set-New-Black-Friday-Cyber-Monday-Record-with-7.5-Billion-in-Sales/default.aspx"
          target="_blank"
          sx={{
            border: "none",
            position: "absolute",
            top: "22px",
            right: "28px",
            "svg path": {
              stroke: "accent",
            },
          }}
        >
          <ExternalLink />
        </Link>
        <Flex sx={{ flexDirection: "column" }}>
          <Counter
            prefix="$"
            number="7.5"
            sx={{
              fontSize: [6, null, 7],
              lineHeight: "111%",
              fontWeight: "bold",
              span: {
                color: "accent",
                fontWeight: "bold",
                fontSize: "inherit",
              },
            }}
          />
          <Text
            as="strong"
            sx={{
              fontSize: [6, null, 7],
              lineHeight: "111%",
              fontWeight: "bold",
            }}
          >
            Billion
          </Text>
        </Flex>
        <Text
          sx={{
            fontSize: 3,
            fontWeight: "semibold",
            lineHeight: "144%",
            maxWidth: "314px",
          }}
        >
          In 2022, Shopify merchants broke records with a Black Friday Cyber
          Monday that saw sales of{" "}
          <Link
            href="https://investors.shopify.com/news-and-events/press-releases/news-details/2022/Shopify-Merchants-Set-New-Black-Friday-Cyber-Monday-Record-with-7.5-Billion-in-Sales/default.aspx"
            target="_blank"
            sx={{ fontSize: "inherit", fontWeight: "inherit" }}
          >
            $7.5 billion and a peak of $3.5 million per minute
          </Link>
          .
        </Text>
      </Flex>
      <Flex
        className="card fadein"
        data-delay="0.5"
        sx={{
          p: ["20px 20px 0", "44px 36px 0px 31px"],
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text
          sx={{
            fontSize: 3,
            fontWeight: "semibold",
            lineHeight: "144%",
            maxWidth: "329px",
            strong: {
              fontWeight: "bold",
              color: "text",
            },
          }}
        >
          To celebrate entrepreneurship and showcase the impact Shopify-powered
          stores have worldwide,{" "}
          <strong>
            Shopify hosts its annual Black Friday Cyber Monday Live Globe.
          </strong>
        </Text>
        <Image src={ipad} className="fadein" data-delay="0.8" />
      </Flex>
    </Grid>
  );
}
