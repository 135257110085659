/** @jsxImportSource theme-ui */
import { Divider, Flex, Heading, Text } from "theme-ui";
import {
  applicationImages,
  brainstorming,
  onboardingImages,
  signupImages,
} from "../../content/trac";
import Spacer from "../../../common/Spacer";
import KeySolutionElements from "./KeySolutionElements";
import Features from "./Features";
import HeadingAndBody from "../../common/HeadingAndBody";
import systemMap from "../../../../assets/trac/systemMap.svg";
import lofiWires from "../../../../assets/trac/lofiWires.png";
import designSys from "../../../../assets/trac/designSys.jpg";

export default function Design() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Design
      </Heading>
      <Divider className="fadein" margin={["55px 0", "55px 0 70px"]} />
      <Heading className="fadein" sx={{ fontSize: 5, mb: "32px" }}>
        {brainstorming.heading}
      </Heading>
      <Text
        className="fadein"
        dangerouslySetInnerHTML={{ __html: brainstorming.body }}
        sx={{
          strong: { fontWeight: "bold", color: "text" },
        }}
      />
      <Spacer height="70px" />
      <KeySolutionElements />
      <Spacer height="89px" />
      <Features />
      <Spacer height="109px" />
      <HeadingAndBody
        heading="System Map"
        image={systemMap}
        sx={{ gap: "54px" }}
        disableModal
      />
      <Spacer height="54px" />
      <HeadingAndBody
        heading="Low Fidelity Wireframes"
        image={lofiWires}
        sx={{ gap: "54px", img: { maxWidth: "1238px", width: "100%" } }}
      />
      <Spacer height={["50px", "105px"]} />
      <HeadingAndBody
        heading="Design System"
        image={designSys}
        sx={{ gap: "54px", img: { maxWidth: "1280px", width: "100%" } }}
      />
      <Spacer height={["50px", "105px"]} />
      <HeadingAndBody
        heading="Onboarding"
        images={onboardingImages}
        sx={{ gap: ["50px", "105px"], "& > div": { gap: "45px" } }}
      />
      <Spacer height={["50px", "105px"]} />
      <HeadingAndBody
        heading="Sign up"
        images={signupImages}
        sx={{ gap: ["50px", "93px"], "& > div": { gap: "45px" } }}
      />
      <Spacer height={["50px", "105px"]} />
      <HeadingAndBody
        heading="Application"
        images={applicationImages}
        sx={{ gap: ["50px", "93px"], "& > div": { gap: "95px" } }}
      />
    </Flex>
  );
}
