/** @jsxImportSource theme-ui */
import { Divider, Flex, Image, Text } from "theme-ui";

export default function IconTextSquare({
  icon,
  text,
  divider = false,
  index,
  large = false,
  className,
}) {
  return (
    <Flex
      className={`outlined ${className} fadein`}
      data-delay={index && (index / 3).toFixed(2)}
      sx={{
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: !divider && "20px",
        width: ["200px", large ? "225px" : "200px"],
        height: ["200px", large ? "225px" : "200px"],
        px: "15px",
        pt: "37px",
      }}
    >
      <Image src={icon} />
      {divider && (
        <Divider
          color="#F3F3F3"
          margin="16px 0 14px"
          sx={{ width: "82px", borderBottom: "4px solid" }}
        />
      )}
      <Text
        sx={{
          fontWeight: "semibold",
          fontSize: large ? 1 : 0,
          lineHeight: "143%",
          textAlign: "center",
        }}
      >
        {text}
      </Text>
    </Flex>
  );
}
