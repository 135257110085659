/** @jsxImportSource theme-ui */
import { Divider, Flex, Heading } from "theme-ui";
import DesignSys from "./DesignSys";
import Spacer from "../../../common/Spacer";
import Goals from "./Goals";
import BizGoals from "./BizGoals";

export default function DesignPhaseTwo() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Design - Phase Two
      </Heading>
      <Divider className="fadein" margin="55px 0" />
      <DesignSys />
      <Spacer height="48px" />
      <Goals />
      <Spacer height="73px" />
      <BizGoals />
    </Flex>
  );
}
