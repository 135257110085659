/** @jsxImportSource theme-ui */
import { Flex, Heading, Text } from "theme-ui";
import { interviews } from "../../content/trac";
import Spacer from "../../../common/Spacer";
import help from "../../../../assets/help-circle.svg";
import IconTextSquare from "../../common/IconTextSquare";
export default function Interviews() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, mb: "32px" }}>
        {interviews.heading}
      </Heading>
      <Text
        className="fadein"
        dangerouslySetInnerHTML={{ __html: interviews.body }}
      />
      <Spacer height="76px" />
      <Flex
        sx={{
          flexDirection: "column",
          gap: "40px",
          maxWidth: "660px",
          m: "0 auto",
        }}
      >
        <Text className="fadein" variant="caption" sx={{ fontSize: 3 }}>
          {interviews.caption}
        </Text>
        <Flex sx={{ gap: "30px", flexWrap: "wrap", justifyContent: "center" }}>
          {interviews.criteria.map((criterion, i) => (
            <IconTextSquare
              key={criterion}
              icon={help}
              text={criterion}
              sx={{ pt: "43px" }}
              index={i}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
