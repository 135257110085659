/** @jsxImportSource theme-ui */
import { useEffect, useState } from "react";
import { Flex, Grid, Heading, Text } from "theme-ui";
import ModalImage from "../../common/ModalImage";

export default function InfoImages({
  info,
  imagePl,
  imagePr,
  imagePy,
  textMaxWidth,
  maxHeight,
  className,
}) {
  const [selected, setSelected] = useState(0);
  const [loop, setLoop] = useState();
  useEffect(() => {
    const interval = setInterval(() => {
      setSelected((selected) => (selected + 1) % info.length);
    }, 2000);
    setLoop(interval);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Flex
      sx={{
        gap: ["50px", "90px", null, "130px"],
        flexDirection: ["column", "row"],
      }}
      className={`${className} fadein`}
    >
      <Grid
        className="outlined"
        sx={{
          display: "grid",
          overflow: "hidden",
          pl: ["20px", imagePl || "50px"],
          pr: ["20px", imagePr || "50px"],
          py: ["20px", imagePy],
          alignItems: "center",
          justifyContent: "center",
          width: [null, "75%"],
        }}
      >
        {info.map(({ heading, image }, i) => (
          <ModalImage
            loading="lazy"
            group="infoImg"
            key={`${heading}-image`}
            sx={{
              gridColumn: 1,
              gridRow: 1,
              opacity: selected === i ? 1 : 0,
              pointerEvents: selected === i ? "auto" : "none",
              transition: "opacity 0.5s ease",
              maxHeight: maxHeight || "740px",
              objectFit: "contain",
            }}
            src={image}
            width="100%"
          />
        ))}
      </Grid>
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          gap: ["0px", "35px", null, "75px"],
          maxWidth: textMaxWidth,
        }}
      >
        {info.map(({ heading, text }, i) => {
          return (
            <Flex
              key={heading}
              as="button"
              onClick={() => {
                setSelected(i);
                clearInterval(loop);
              }}
              sx={{
                flexDirection: "column",
                gap: "8px",
                textAlign: "left",
                background: "none",
                border: "none",
                cursor: "pointer",
                p: ["15px", 0],
                borderRadius: "10px",
                m: 0,
                ...(selected === i && {
                  backgroundColor: ["rgba(0,0,0,0.05)", "transparent"],
                }),
                color: "text",
                transition: "background-color 0.3s ease",
              }}
            >
              <Heading
                sx={{
                  fontSize: [4, null, null, 5],
                  color: selected !== i ? "text" : "strong",
                  transition: "color 0.3s ease",
                }}
              >
                {heading}
              </Heading>
              <Text
                sx={{
                  fontSize: [2, null, null, 3],
                  fontWeight: "semibold",
                  lineHeight: "162%",
                  color: "text",
                }}
              >
                {text}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}
