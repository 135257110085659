/** @jsxImportSource theme-ui */
import { Divider, Flex, Heading, Text } from "theme-ui";
import Spacer from "../../../common/Spacer";

export default function Takeaways() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Takeaways
      </Heading>
      <Divider className="fadein" margin="55px 0 55px" />
      <Text className="fadein" variant="caption" sx={{ fontSize: 3 }}>
        Being the listener
      </Text>
      <Spacer height="18px" />
      <Text
        className="fadein"
        sx={{ fontWeight: "semibold", maxWidth: "1577px" }}
      >
        Conducting remote interviews really put me on the spot, but I was able
        to find my footing. I did my best to let conversations free-flow so I
        could gain unique insights from each of my interviews. Often I would get
        similar opinions but frustrations that varied. Each interview provided
        me with new perspectives that I could incorporate into our
        brain-storming.
      </Text>
      <Spacer height="40px" />
      <Text className="fadein" variant="caption" sx={{ fontSize: 3 }}>
        For next time
      </Text>
      <Spacer height="18px" />
      <Text className="fadein" sx={{ fontWeight: "semibold" }}>
        I would've liked to expand more on the feature set of collaboration
        amongst students. It was a reoccurring paint point that I felt we
        could've addressed better if we had more time.
      </Text>
    </Flex>
  );
}
