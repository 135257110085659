/** @jsxImportSource theme-ui */
import { Divider, Flex, Heading } from "theme-ui";
import InfoArch from "./InfoArch";
import Spacer from "../../../common/Spacer";
import UserFlow from "./UserFlow";
import Takeaways from "./Takeaways";
import Wireframes from "./Wireframes";
import Outcomes from "./Outcomes";

export default function DesignPhaseOne() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Design - Phase One
      </Heading>
      <Divider className="fadein" margin={["55px 0", "55px 0 107px"]} />
      <InfoArch />
      <Spacer height="48px" />
      <UserFlow />
      <Spacer height="48px" />
      <Takeaways />
      <Spacer height="48px" />
      <Wireframes />
      <Spacer height="106px" />
      <Outcomes />
    </Flex>
  );
}
