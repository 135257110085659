import trac from "../../../assets/trac/trac.png";
import alert from "../../../assets/alert-circle.svg";
import target from "../../../assets/target.svg";
import info from "../../../assets/trac/info.svg";
import search from "../../../assets/search.svg";
import clock from "../../../assets/trac/clock.svg";
import file from "../../../assets/trac/file.svg";
import home from "../../../assets/home.svg";
import calendar from "../../../assets/trac/calendar.svg";
import onb0 from "../../../assets/trac/onb0.png";
import onb1 from "../../../assets/trac/onb1.png";
import onb2 from "../../../assets/trac/onb2.png";
import onb3 from "../../../assets/trac/onb3.png";
import signup1 from "../../../assets/trac/signup1.png";
import signup2 from "../../../assets/trac/signup2.png";
import signup3 from "../../../assets/trac/signup3.png";
import app1 from "../../../assets/trac/app1.png";
import app2 from "../../../assets/trac/app2.png";
import app3 from "../../../assets/trac/app3.png";
import app4 from "../../../assets/trac/app4.png";
import dashboard from "../../../assets/trac/dashboard.png";
import calendar2 from "../../../assets/trac/calendar2.png";
import tasks from "../../../assets/trac/tasks.png";

const sharedImageStyles = {
  alignItems: "center",
  gap: "0px",
  justifyContent: "flex-start",
  span: {
    fontSize: 1,
    color: "#505050",
    fontWeight: "bold",
  },
};

export const content = {
  hero: {
    heading: "Trac",
    heroImage1: { path: trac, width: "589px", ml: ["20px", "250px"] },
    py: "45px",
    overview: {
      demoLink:
        "https://www.figma.com/proto/UH5EO1vWdPOG8AbPfqGR7U/Trac-Prototype?node-id=0%3A1&viewport=575%2C935%2C0.1355305165052414&scaling=scale-down",
      demoText: "Live Prototype",
      roles: ["User Researcher", "Prototyping", "Visual Design"],
      duration: "2 Months",
      team: "Rohail Khan",
      tools: ["Figma"],
    },
  },
};

export const overviewCards = [
  {
    heading: "The Problem",
    text: `With remote learning on the rise due to the ongoing COVID-19 pandemic Trac is an application aimed at students with the goal of creating an intuitive platform that promotes self-regulation and time management amongst students.`,
    image: alert,
  },
  {
    heading: "The Challenge",
    text: `<strong>
    How might we develop a mobile application that helps students keep track of their course work and manage their time?
  </strong>`,
    image: target,
  },
];

export const interviews = {
  heading: "Interviews",
  body: `To be able to empathise with how students have been operating in the new e-learning environment we conducted nine user interviews from students in various university and college programs.  We asked questions focusing on three main metrics.`,
  caption: "Interview Question Criteria",
  criteria: ["Self Regulation", "Communication", "Engagement"],
};

export const insights = {
  heading: "Insights",
  items: [
    {
      icon: info,
      heading: "Troubles around self-organizing deadlines",
      body: `“I waste time with current e-learning platforms trying to make sense of things that aren't set up properly, or just missing information.”`,
    },
    {
      icon: search,
      heading: "Troubles locating information",
      body: `“Sometimes they post it on slate and not on teams, so we get confused. So we have to go to slate to find it. Submissions for certain projects appear on teams but not on slate”`,
    },
    {
      icon: clock,
      heading: "Students felt it was difficult to manage their time",
      body: `“I did start to use the tool called notion - over time i stopped using it and started to rely on texting my classmates to understand what was going on and I started to comment on the team's channel itself to know what was going on.”`,
    },
  ],
};

export const brainstorming = {
  heading: "Brainstorming",
  body: `After reviewing and gaining insights from our research we moved onto iterating over the features we would like in our application. <strong>This would range from deciding the intended user-flow, addressing key pain points from our insights, and finally a system map to base the flow on.</strong>`,
};

export const keySolElems = {
  heading: "Key Solution Elements from Needs:",
  items: [
    "Quick overview of all upcoming assignments and deadlines",
    "Share group project deadlines with peers",
    "Create their own tasks and projects",
  ],
};

export const features = {
  heading: "Features to address these needs",
  items: [
    {
      icon: file,
      caption: "Tasks",
      text: `
      Priority<br/>
      Notifications<br/>
      Sync Platforms<br/>
      Peer Collaboration
      `,
    },
    {
      icon: home,
      caption: "Dashboard",
      text: `All components from experience into one concise page`,
    },
    {
      icon: calendar,
      caption: "Calendar",
      text: `Weekly / Monthly<br/>
      Assignments / Tasks`,
    },
  ],
};

export const onboardingImages = [
  {
    caption: "Getting Started",
    src: onb0,
    imageStyles: sharedImageStyles,
    imgGroup: "onboarding",
  },
  {
    caption: "Onboarding #1",
    src: onb1,
    imageStyles: sharedImageStyles,
    imgGroup: "onboarding",
  },
  {
    caption: "Onboarding #2",
    src: onb2,
    imageStyles: sharedImageStyles,
    imgGroup: "onboarding",
  },
  {
    caption: "Onboarding #3",
    src: onb3,
    imageStyles: sharedImageStyles,
    imgGroup: "onboarding",
  },
];

export const signupImages = [
  {
    caption: "Create Account",
    src: signup1,
    imageStyles: sharedImageStyles,
    imgGroup: "signup",
  },
  {
    caption: "Sync Apps",
    src: signup2,
    imageStyles: sharedImageStyles,
    imgGroup: "signup",
  },
  {
    caption: "Sign In",
    src: signup3,
    imageStyles: sharedImageStyles,
    imgGroup: "signup",
  },
];

export const applicationImages = [
  {
    caption: "Dashboard",
    src: app1,
    imageStyles: sharedImageStyles,
    imgGroup: "application",
  },
  {
    caption: "Calendar",
    src: app2,
    imageStyles: sharedImageStyles,
    imgGroup: "application",
  },
  {
    caption: "Create a Task",
    src: app3,
    imageStyles: sharedImageStyles,
    imgGroup: "application",
  },
  {
    caption: "Profile",
    src: app4,
    imageStyles: sharedImageStyles,
    imgGroup: "application",
  },
];

export const infoImages = [
  {
    heading: "Dashboard",
    text: "Provides users with an an overview of their week. At a glance they are able to see upcoming deadlines and on going projects.",
    image: dashboard,
  },
  {
    heading: "Calendar",
    text: "Features all created tasks colour coded by the user in a monthly view.",
    image: calendar2,
  },
  {
    heading: "Tasks",
    text: "Tasks can be imported through the students e-learning platform or manually created by the student. To promote collaboration Trac tasks can also be shared with other students.",
    image: tasks,
  },
];
