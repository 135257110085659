import React from "react";
import ReactDOM from "react-dom/client";
import Layout from "./Layout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Home from "./pages/Home";
import About from "./pages/About";
import { ROUTES } from "./utils/constants";
import ShopifyBFCM from "./pages/ShopifyBFCM";
import Goodehealth from "./pages/Goodehealth";
import Trac from "./pages/Trac";

const router = createBrowserRouter([
  {
    path: ROUTES.HOME.path,
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: ROUTES.ABOUT.path,
        element: <About />,
      },
      {
        path: ROUTES.SHOPIFY_BFCM.path,
        element: <ShopifyBFCM />,
      },
      {
        path: ROUTES.GOODEHEALTH.path,
        element: <Goodehealth />,
      },
      {
        path: ROUTES.TRAC.path,
        element: <Trac />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
