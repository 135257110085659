import { Box, Flex, Heading, Image, Text } from "theme-ui";
import { compAnalysis } from "../../content/goodehealth";
import Spacer from "../../../common/Spacer";
import { ReactComponent as LineCircle } from "../../../../assets/goodehealth/lineCircle.svg";

/** @jsxImportSource theme-ui */
export default function CompAnalysis() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {compAnalysis.heading}
      </Heading>
      <Spacer height={["40px", "90px"]} />
      <Text
        className="fadein"
        dangerouslySetInnerHTML={{ __html: compAnalysis.body }}
        sx={{
          fontSize: 3,
          lineHeight: "163%",
          strong: { fontWeight: "bold", color: "text" },
        }}
      />
      <Spacer height="52px" />
      <Box sx={{ margin: "0 auto" }}>
        <Text
          className="fadein"
          variant="caption"
          sx={{ fontSize: 3, lineHeight: "normal" }}
        >
          {compAnalysis.takeaways.caption}
        </Text>
        <Spacer height={["40px", "15px"]} />
        <Flex
          sx={{
            gap: ["40px", "80px"],
            alignItems: "center",
            flexDirection: ["column", null, "row"],
          }}
        >
          <Image
            className="fadein"
            src={compAnalysis.takeaways.image}
            sx={{ flexShrink: 1 }}
          />
          <LineCircle className="fadein" />
          <Flex
            sx={{ gap: "30px", flexWrap: "wrap", justifyContent: "center" }}
          >
            {compAnalysis.takeaways.items.map(({ icon, text }, i) => (
              <Flex
                className="outlined fadein"
                data-delay={(i / 3).toFixed(2)}
                key={text}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  flexDirection: "column",
                  p: "43px 22px",
                  textAlign: "center",
                  flexGrow: 0,
                  width: "200px",
                }}
              >
                <Image src={icon} />
                <Text sx={{ fontWeight: "semibold", lineHeight: "143%" }}>
                  {text}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
