import Hero from "../components/home/Hero";
import Spacer from "../components/common/Spacer";
import Work from "../components/home/Work";

export default function Home() {
  return (
    <>
      <Hero />
      <Work />
      <Spacer height={["100px", "400px"]} />
    </>
  );
}
