/** @jsxImportSource theme-ui */

import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Link,
  Text,
} from "theme-ui";
import Me from "./Me";
import { ReactComponent as Linkedin } from "../../assets/linkedin.svg";
import { ReactComponent as Mail } from "../../assets/mail.svg";
import { ReactComponent as Resume } from "../../assets/resume.svg";
import { LINKS } from "../../utils/constants";
import { about } from "./content";

export default function Hero() {
  return (
    <Grid
      sx={{
        p: ["50px 0 50px", null, "161px 0 53px"],
        gridTemplateColumns: ["auto auto", null, "auto 1fr"],
        gridTemplateRows: "auto 1fr",
        gap: ["30px", null, "37px 60px", "37px 169px"],
        display: ["flex", "grid"],
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Me
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: 2,
          gridRowStart: [2, null, 1],
          gridRowEnd: 3,
          p: [null, "0 0 3px 6px"],
          zIndex: 1,
          alignSelf: [null, "flex-end"],
        }}
      />
      <Flex
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          pt: [0, "50px"],
          gridColumnStart: [1, null, 2],
          gridColumnEnd: 3,
          flexDirection: ["column", "row"],
        }}
      >
        <Heading
          as="strong"
          className="fadein"
          data-delay="0.2"
          sx={{ fontSize: 5, fontWeight: "semibold", lineHeight: "normal" }}
        >
          About Me
        </Heading>
        <Flex
          sx={{
            mt: ["25px", "5px"],
            gap: "9px",
            alignItems: "center",
          }}
        >
          <IconButton
            as={Link}
            href={LINKS.LINKEDIN}
            target="__blank"
            className="fadein"
            data-delay="0.6"
          >
            <Linkedin />
          </IconButton>
          <IconButton
            variant="iconStroke"
            as={Link}
            href={LINKS.EMAIL}
            className="fadein"
            data-delay="0.7"
          >
            <Mail />
          </IconButton>
          <Box
            sx={{
              ml: "11px",
            }}
            className="fadein"
            data-delay="0.9"
          >
            <Button
              as={Link}
              href={LINKS.RESUME}
              target="_blank"
              sx={{
                lineHeight: "160%",
                minWidth: "150px",
                svg: {
                  mr: "7px",
                  width: "14px",
                  height: "auto",
                },
              }}
            >
              <Resume /> Resume
            </Button>
          </Box>
        </Flex>
      </Flex>
      <Box
        className="fadein"
        data-delay="0.1"
        sx={{
          gridRowStart: 2,
          gridColumnStart: 1,
          gridColumnEnd: 3,
          backgroundColor: "#EFF1F2 ",
          borderRadius: "20px",
          minHeight: [null, null, "448px", "0"],
          display: ["none", "block"],
          width: "100%",
          height: "100%",
        }}
      />
      <Flex
        sx={{
          gridRowStart: 2,
          gridColumnStart: 2,
          gridColumnEnd: 3,
          flexDirection: "column",
          justifyContent: ["center", null, "flex-start"],
          gap: ["15px", "36px"],
          pt: ["0", null, "60px"],
          pb: ["30px", null, "50px"],
          pr: [0, "30px"],
          minHeight: [null, null, "448px", "0"],
          span: {
            fontSize: [2, null, null, 3],
            fontWeight: "bold",
            lineHeight: "166%",
          },
        }}
      >
        <Flex
          sx={{ gap: ["15px", "31px"], flexDirection: ["column", null, "row"] }}
        >
          <Text
            className="fadein"
            data-delay="0.2"
            dangerouslySetInnerHTML={{ __html: about[0] }}
            sx={{ maxWidth: [null, "442px"] }}
          />
          <Text
            className="fadein"
            data-delay="0.3"
            dangerouslySetInnerHTML={{ __html: about[1] }}
          />
        </Flex>
        <Text
          className="fadein"
          data-delay="0.4"
          dangerouslySetInnerHTML={{ __html: about[2] }}
          sx={{ maxWidth: [null, "910px"] }}
        />
      </Flex>
    </Grid>
  );
}
