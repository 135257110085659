import { Flex, Heading, Text } from "theme-ui";
import { evaluation } from "../../content/goodehealth";
import { ReactComponent as X } from "../../../../assets/goodehealth/x.svg";
import ModalImage from "../../../common/ModalImage";

/** @jsxImportSource theme-ui */
export default function Evaluation() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex sx={{ gap: ["40px", "35px"], flexWrap: "wrap" }}>
        <Flex sx={{ flexDirection: "column", gap: "30px" }}>
          <Heading className="fadein" sx={{ fontSize: 5 }}>
            {evaluation.heading}
          </Heading>
          <Text
            className="fadein"
            dangerouslySetInnerHTML={{ __html: evaluation.body }}
            sx={{
              fontSize: 3,
              lineHeight: "163%",
              strong: { fontWeight: "bold", color: "text" },
              maxWidth: "582px",
            }}
          />
        </Flex>
        <ImageAndCaption
          image={evaluation.image}
          caption={evaluation.caption}
        />
      </Flex>
      <Text
        className="fadein"
        sx={{ m: ["50px 0", "87px 0 54px"], fontSize: 3 }}
      >
        {evaluation.issuesHeading}
      </Text>
      <Flex sx={{ gap: ["40px", "90px"], flexDirection: "column" }}>
        {evaluation.issues.map(
          ({ heading, image, imageStyles, caption, items }) => (
            <Flex
              className="fadein"
              key={items[0]}
              sx={{
                gap: ["40px", "75px"],
                pl: ["40px", "80px"],
                pr: ["20px", "40px"],
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Flex sx={{ gap: "30px", flexDirection: "column" }}>
                <Text variant="caption" sx={{ fontSize: 3 }}>
                  {heading}
                </Text>
                <Flex sx={{ flexDirection: "column", gap: "8px" }}>
                  {items.map((item) => (
                    <Flex
                      key={item}
                      sx={{
                        gap: ["15px", "28px"],
                        alignItems: "center",
                        svg: { flexShrink: 0, width: ["30px", "auto"] },
                      }}
                    >
                      <X />
                      <Text sx={{ fontWeight: "semibold" }}>{item}</Text>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
              <ImageAndCaption
                image={image}
                imageStyles={imageStyles}
                caption={caption}
              />
            </Flex>
          )
        )}
      </Flex>
    </Flex>
  );
}

function ImageAndCaption({ image, imageStyles = {}, caption }) {
  return (
    <Flex sx={{ flexDirection: "column", gap: "30px", textAlign: "center" }}>
      <ModalImage
        group="eval"
        className="fadein"
        src={image}
        sx={{ maxWidth: "931px", ...imageStyles }}
      />
      <Text className="fadein" variant="caption" sx={{ fontSize: 3 }}>
        {caption}
      </Text>
    </Flex>
  );
}
