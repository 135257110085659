export const theme = {
  breakpoints: ["1025px", "1440px", "1920px"],
  fonts: {
    body: "Sora, system-ui, sans-serif",
    heading: 'Sora, "Avenir Next", sans-serif',
    monospace: "Sora, Menlo, monospace",
  },
  colors: {
    text: "#6E6E7F",
    strong: "#2C2C2C",
    background: "#FFFFFF",
    primary: "#4067CE",
    secondary: "#AAAAAA",
    accent: "#4387D6",
    muted: "#6E6E7F",
  },
  fontSizes: [
    "1.2rem", // 0
    "1.4rem", // 1
    "1.5rem", // 2
    "1.6rem", // 3
    "2.4rem", // 4
    "3.2rem", // 5
    "4.8rem", // 6
    "6.4rem", // 7
    "12.8rem", // 8
  ],
  fontWeights: {
    body: 200,
    semibold: 300,
    bold: 400,
  },
  styles: {
    root: {
      lineHeight: "normal",
      padding: [
        "var(--top-margin) 32px 100px",
        "var(--top-margin) 132px 200px",
      ],
      overflowX: "hidden",
      strong: { color: "strong", fontWeight: "bold" },
    },
    a: {
      color: "accent",
      fontWeight: "bold",
      fontFamily: "body",
      fontSize: 0,
      lineHeight: "180%",
      borderBottom: "1px solid",
      textDecoration: "none",
    },
    hr: {
      color: "muted",
    },
  },
  text: {
    default: {
      fontWeight: "semibold",
      fontFamily: "body",
      fontSize: 1,
      lineHeight: "186%",
    },
    heading: {
      fontWeight: "semibold",
      fontFamily: "heading",
      fontSize: 4,
      color: "strong",
    },
    caption: {
      fontWeight: "semibold",
      fontFamily: "body",
      fontSize: 2,
      color: "muted",
    },
  },
  links: {
    email: {
      fontSize: "inherit",
      fontWeight: "inherit",
      border: "none",
      textDecoration: "underline",
      lineHeight: "inherit",
      color: "accent",
    },
    nav: {
      color: "text",
      fontFamily: "body",
      textDecoration: "none",
      fontWeight: "bold",
      fontSize: 0,
    },
  },
  buttons: {
    primary: {
      bg: "background",
      color: "strong",
      fontFamily: "body",
      fontWeight: "bold",
      borderRadius: "18px",
      border: "2px solid",
      borderColor: "strong",
      px: ["18px", "23px"],
      height: ["70px", "75px"],
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "background 0.1s ease, color 0.1s ease",
      fontSize: 3,
      path: { stroke: "strong", transition: "stroke 0.1s ease" },
      "&:hover": {
        bg: "strong",
        color: "background",
        path: { stroke: "background" },
      },
    },
    icon: {
      px: 0,
      py: "23px",
      width: ["65px", "70px"],
      height: ["65px", "70px"],
      borderRadius: "50%",
      bg: "strong",
      svg: {
        width: "auto",
        height: "100%",
      },
      "svg path": {
        fill: "background",
      },
    },
    iconStroke: {
      px: 0,
      py: "20px",
      width: ["65px", "70px"],
      height: ["65px", "70px"],
      borderRadius: "50%",
      bg: "strong",
      svg: {
        width: "auto",
        height: "100%",
      },
      "svg path": {
        stroke: "background",
      },
    },
    filled: {
      bg: "strong",
      fontFamily: "body",
      fontWeight: "bold",
      borderRadius: "50px",
      width: "160px",
      height: "41px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "background 0.1s ease",
      fontSize: 0,
      "&:hover": {
        bg: "primary",
      },
      "&:active": {
        bg: "secondary",
        transition: "background 0s",
      },
    },
  },
};
