/** @jsxImportSource theme-ui */
import { Flex, Grid, Heading, Image, Text } from "theme-ui";
import { designSys } from "../../content/goodehealth";

export default function DesignSys() {
  return (
    <Flex sx={{ flexDirection: "column", gap: "48px" }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {designSys.heading}
      </Heading>
      <Text
        className="fadein"
        dangerouslySetInnerHTML={{ __html: designSys.body }}
        sx={{ fontSize: 3 }}
      />
      <Grid
        columns="1fr 1fr"
        gap="48px 0"
        sx={{
          img: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            objectPosition: "left",
          },
        }}
      >
        {designSys.images.map(({ caption, src, style = {} }) => (
          <Flex
            key={caption + src}
            sx={{ flexDirection: "column", gap: "45px", ...style }}
          >
            {caption && (
              <Text className="fadein" variant="caption" sx={{ fontSize: 3 }}>
                {caption}
              </Text>
            )}
            <Image
              group="designsys"
              className="fadein"
              src={src}
              sx={{ minHeight: 0 }}
            />
          </Flex>
        ))}
      </Grid>
    </Flex>
  );
}
