/** @jsxImportSource theme-ui */
import { Flex, Heading } from "theme-ui";
import WorkTile from "./WorkTile";
import { WORK_TILES } from "../../utils/constants";
import { fadeInUp } from "../../utils/animations";
import { useLocation } from "react-router-dom";

export default function Work() {
  const location = useLocation();
  return (
    <Flex
      id="work"
      sx={{
        flexDirection: "column",
        gap: ["35px", "122px"],
        pt: "var(--home-hero-work-spacer)",
        animation: `${fadeInUp} 1.3s cubic-bezier(0.24, 0.74, 0.42, 0.98)`,
        animationFillMode: "both",
        animationDelay: location.hash === "#work" ? "0s" : "2s",
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          gap: "11px",
        }}
      >
        <Heading as="strong" sx={{ fontWeight: "bold" }}>
          My Work
        </Heading>
      </Flex>
      <Flex sx={{ gap: "70px", flexDirection: ["column", "row"] }}>
        {WORK_TILES.map((tile, i) => (
          <WorkTile key={tile.link} index={i} {...tile} />
        ))}
      </Flex>
    </Flex>
  );
}
