/** @jsxImportSource theme-ui */
import { Divider, Flex, Heading, Text } from "theme-ui";
import Spacer from "../../../common/Spacer";
import InfoImages from "../../common/InfoImages";
import { infoImages, solutionSections } from "../../content/shopifyBFCM";
import HeadingAndBody from "../../common/HeadingAndBody";
import shoutout from "../../../../assets/shopifyBFCM/shoutout.jpg";
import ModalImage from "../../../common/ModalImage";

export default function Solution() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Solution
      </Heading>
      <Divider className="fadein" margin={["32px 0 50px", "32px 0 116px"]} />
      <InfoImages info={infoImages} />
      <Spacer height={["50px", "178px"]} />
      <HeadingAndBody {...solutionSections[0]} />
      <Spacer height={["50px", "70px"]} />
      <HeadingAndBody {...solutionSections[1]} />
      <Spacer height={["90px", "178px"]} />
      <Flex sx={{ flexDirection: "column" }}>
        <Heading className="fadein" sx={{ fontSize: [4, 5] }}>
          🎉 Shoutout to the team!
        </Heading>
        <Spacer height={["30px", "66px"]} />
        <Text
          className="fadein"
          sx={{
            fontSize: 3,
            fontWeight: "semibold",
            lineHeight: "163%",
          }}
        >
          Overall, working on this project was extremely satisfying, and I
          couldn't have asked for a better group of individuals to work with.
          The project pushed me outside of my comfort zone by attempting 3D,
          playing around with code, and trying my hand at marketing UX.
        </Text>
        <Spacer height={["50px", "93px"]} />
        <ModalImage
          className="fadein"
          src={shoutout}
          sx={{ maxWidth: [null, "620px"], m: "0 auto" }}
        />
      </Flex>
      <Spacer height="63px" />
    </Flex>
  );
}
