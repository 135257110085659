/** @jsxImportSource theme-ui */
import { Flex, Heading } from "theme-ui";
import { designRequirements } from "../../content/shopifyBFCM";
import IconTextSquare from "../../common/IconTextSquare";

export default function DesignRequirements() {
  const { heading, requirements } = designRequirements;
  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: "50px",
        alignItems: "center",
      }}
    >
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {heading}
      </Heading>
      <Flex sx={{ gap: "23px", flexWrap: "wrap", justifyContent: "center" }}>
        {requirements.map((requirement, i) => (
          <IconTextSquare
            large
            index={i}
            key={requirement.text}
            {...requirement}
          />
        ))}
      </Flex>
    </Flex>
  );
}
