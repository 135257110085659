/** @jsxImportSource theme-ui */
import { useEffect, useRef, useState } from "react";
import { Flex, Text } from "theme-ui";
import { slideUp } from "../../../utils/animations";

export default function Counter({ prefix, number = "", suffix, className }) {
  const [height, setHeight] = useState("0px");
  const heightRef = useRef();
  useEffect(() => {
    if (heightRef.current) {
      setHeight(heightRef.current.clientHeight);
    }
  }, [heightRef]);
  return (
    <Flex
      sx={{
        justifyContent: "flex-end",
        overflow: "hidden",
        height: `${height}px`,
        span: { lineHeight: "normal !important" },
      }}
      className={className}
    >
      <Text
        ref={heightRef}
        sx={{
          position: "absolute",
          visibility: "hidden",
          pointerEvents: "none",
        }}
      >
        0
      </Text>
      {prefix && <Text>{prefix}</Text>}
      {number.split("").map((num, i) =>
        Number.isNaN(parseInt(num)) ? (
          <Text key={num + i}>{num}</Text>
        ) : (
          <Flex
            className="counter"
            key={num + i}
            sx={{
              flexDirection: "column",
              alignItems: "center",
              transition: "transform 0.5s ease",
              transform: "translateY(0%)",
              animation: `${slideUp(height, parseInt(num))} 2.25s ease`,
              animationFillMode: "forwards",
              animationPlayState: "paused",
              animationDelay: "0.5s",
              height: "fit-content",
              ...(i !== 0 && { ml: ["-3px", "-5px"] }),
            }}
          >
            {[...Array(parseInt(num) + 1).keys()].map((n) => (
              <Text key={num + i + n}>{n}</Text>
            ))}
          </Flex>
        )
      )}
      {suffix && <Text>{suffix}</Text>}
    </Flex>
  );
}
