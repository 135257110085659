/** @jsxImportSource theme-ui */
import { Flex, Heading } from "theme-ui";
import OverviewCards from "./OverviewCards";
import Spacer from "../../../common/Spacer";
import Timeline from "../../common/Timeline";

export default function Overview() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Overview
      </Heading>
      <Spacer height={["50px", "90px"]} />
      <OverviewCards />
      <Spacer height="90px" />
      <Timeline
        title="Design Timeline"
        sections={[
          {
            heading: "Research",
            gridColumnStart: 1,
            details: [
              {
                items: ["User Interviews"],
              },
            ],
          },
          {
            heading: "Design",
            gridColumnStart: 2,
            gridColumnEnd: 4,
            details: [
              {
                items: ["Brainstorming, Wireframes, System Map, Visual Design"],
              },
            ],
          },
          {
            heading: "Prototype",
            gridColumnStart: 4,
            details: [
              {
                items: ["High Fidelity"],
              },
            ],
          },
        ]}
      />
    </Flex>
  );
}
