/** @jsxImportSource theme-ui */
import { useRouteError } from "react-router-dom";
import { Flex, Heading, ThemeProvider } from "theme-ui";
import { theme } from "./theme";
import Nav from "./components/common/Nav";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <ThemeProvider theme={theme}>
      <Nav />
      <Flex
        sx={{ height: "300px", justifyContent: "center", alignItems: "center" }}
      >
        <Heading>{error.statusText || error.message}</Heading>
      </Flex>
    </ThemeProvider>
  );
}
