import { Breadcrumbs } from "../components/common/Breadcrumbs";
import Spacer from "../components/common/Spacer";
import Footer from "../components/caseStudies/common/Footer";
import Hero from "../components/caseStudies/common/Hero";
import { hero } from "../components/caseStudies/content/shopifyBFCM";
import Context from "../components/caseStudies/shopifyBFCM/Context";
import Solution from "../components/caseStudies/shopifyBFCM/Solution";

export default function ShopifyBFCM() {
  return (
    <>
      <Spacer height={["40px", "125px"]} />
      <Breadcrumbs
        list={["Work", "Shopify Internship Projects", "BFCM 2022 Globe"]}
      />
      <Hero content={hero} />
      <Spacer height="64px" />
      <Context />
      <Spacer height="54px" />
      <Solution />
      <Footer
        links={[
          { url: "/goodehealth", text: "Goodehealth" },
          { url: "/trac", text: "Trac" },
        ]}
      />
      <Spacer height={["50px", "126px"]} />
    </>
  );
}
