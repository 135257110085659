/** @jsxImportSource theme-ui */

import { Flex, Heading, Text } from "theme-ui";
import { wireframes } from "../../content/goodehealth";
import ModalImage from "../../../common/ModalImage";

export default function Wireframes() {
  return (
    <Flex sx={{ gap: "48px", flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        {wireframes.heading}
      </Heading>
      {wireframes.sections.map((section) => {
        const keys = Object.keys(section);
        return keys.map((key, i) => {
          switch (key) {
            case "caption":
              return (
                <Text
                  className="fadein"
                  key={key + section[key].substring(0, 7)}
                  variant="caption"
                >
                  {section[key]}
                </Text>
              );
            case "heading":
              return (
                <Heading
                  className="fadein"
                  key={key + section[key].substring(0, 7)}
                  sx={{ fontSize: 5 }}
                >
                  {section[key]}
                </Heading>
              );
            case "image":
              return (
                <ModalImage
                  group="wireframe"
                  className="fadein"
                  key={key + section[key].substring(0, 7)}
                  src={section[key]}
                  sx={{ px: [0, "35px"] }}
                />
              );
            case "body":
              return (
                <Text
                  className="fadein"
                  key={key + section[key].substring(0, 7)}
                  dangerouslySetInnerHTML={{ __html: section[key] }}
                  sx={{
                    fontSize: 3,
                    strong: { fontWeight: "bold", color: "text" },
                  }}
                />
              );
            case "images":
              return (
                <Flex
                  key={"wireframe" + key + i}
                  sx={{
                    justifyContent: "center",
                    gap: "56px",
                    flexWrap: "wrap",
                  }}
                >
                  {section[key].map(({ src, caption }, i) => (
                    <Flex
                      className="fadein"
                      data-delay={(i / 3).toFixed(2)}
                      key={caption}
                      sx={{
                        flexDirection: "column",
                        gap: "33px",
                        alignItems: "center",
                      }}
                    >
                      <ModalImage group="wireframe" src={src} />
                      <Text variant="caption">{caption}</Text>
                    </Flex>
                  ))}
                </Flex>
              );
            default:
              break;
          }
          return null;
        });
      })}
    </Flex>
  );
}
