/** @jsxImportSource theme-ui */
import { Fragment } from "react";
import { Box, Flex, Grid, Heading, Text } from "theme-ui";

export default function Timeline({ title, sections }) {
  return (
    <Flex sx={{ flexDirection: "column", gap: "77px" }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {title}
      </Heading>
      <Box
        sx={{
          position: "relative",
          ":before": {
            content: ["''", "none"],
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(to right, transparent 75%, rgba(255,255,255,1))",
            pointerEvents: "none",
          },
        }}
      >
        <Box
          sx={{
            overflowX: ["scroll", "auto"],
            overflowY: "hidden",
            pb: ["25px", 0],
          }}
        >
          <Grid
            columns="repeat(4, 1fr)"
            gap="0"
            sx={{ minWidth: "max-content", pr: ["55px", 0] }}
          >
            {sections.map(
              ({ heading, gridColumnStart, gridColumnEnd, details }, i) => {
                return (
                  <Fragment key={heading}>
                    <Heading
                      className="fadein"
                      data-delay="0.5"
                      sx={{
                        fontSize: 3,
                        gridColumnStart,
                        gridColumnEnd,
                        gridRow: 1,
                      }}
                    >
                      {heading}
                    </Heading>
                    <Box
                      className="fadein"
                      sx={{
                        gridColumnStart,
                        gridColumnEnd,
                        gridRow: 2,
                        backgroundColor: i === 1 ? "white" : "#F3F3F3",
                        height: "59px",
                        border: i === 1 && "1px solid",
                        mt: "16px",
                        mb: "26px",
                      }}
                    />
                    {details.map((detail, i) => (
                      <Flex
                        className="fadein"
                        data-delay="1"
                        key={`${heading}-detail-${i}`}
                        sx={{
                          gap: "38px",
                          gridRow: 3,
                          ...(details.length === 1 && {
                            gridColumnStart,
                            gridColumnEnd,
                          }),
                          pr: "15px",
                          transform: "none !important",
                        }}
                      >
                        {detail.subheading && (
                          <Text sx={{ fontWeight: "semibold" }}>
                            {detail.subheading}
                          </Text>
                        )}
                        <ul sx={{ listStyle: "none", m: 0, p: 0 }}>
                          {detail.items.map((item) => (
                            <Text
                              key={item}
                              as="li"
                              sx={{
                                lineHeight: "214%",
                                fontWeight: "semibold",
                              }}
                            >
                              {item}
                            </Text>
                          ))}
                        </ul>
                      </Flex>
                    ))}
                  </Fragment>
                );
              }
            )}
          </Grid>
        </Box>
      </Box>
    </Flex>
  );
}
