/** @jsxImportSource theme-ui */
import { Box, Flex, Image, Text } from "theme-ui";
import { shippingFreights } from "../../content/shopifyBFCM";
import { ReactComponent as ShippingFreightsText } from "../../../../assets/shopifyBFCM/shippingfreights.svg";
import { ReactComponent as ShippingFreightsBar } from "../../../../assets/shopifyBFCM/shippingfreights-bar.svg";
import { useState } from "react";

export default function ShippingFreights() {
  const [switchOn, setSwitchOn] = useState(false);
  return (
    <Flex
      className="fadein"
      sx={{
        flexDirection: "column",
        alignItems: "center",
        gap: "38px",
      }}
    >
      <Flex
        sx={{
          gap: "66px",
          flexDirection: ["column", "row"],
          alignItems: "stretch",
        }}
      >
        <Image className="fadein" src={shippingFreights.globe1} />
        <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
          <Flex
            className="fadein"
            data-delay="0.3"
            sx={{
              flexDirection: "column",
              p: "23px 21px",
              gap: "14px",
              borderRadius: "18px",
              background: "rgba(255, 255, 255, 0.50)",
              boxShadow: "0px 4px 34px 0px rgba(180, 189, 214, 0.25)",
            }}
          >
            <ShippingFreightsText />
            <ShippingFreightsBar />
            <Flex
              as="button"
              onClick={() => setSwitchOn(!switchOn)}
              sx={{
                border: "none",
                width: "50px",
                height: "25px",
                padding: "0",
                borderRadius: "25px",
                backgroundColor: switchOn ? "#2C6ECB" : "#EEF1FB",
                alignItems: "center",
                position: "relative",
                transition: "background-color 0.2s ease",
              }}
            >
              <Box
                sx={{
                  width: "19px",
                  height: "19px",
                  borderRadius: "25px",
                  backgroundColor: "white",
                  boxShadow: "-2.2px 2.2px 9px rgba(0, 0, 0, 0.25)",
                  position: "absolute",
                  top: "3px",
                  left: switchOn ? "26.5px" : "4.5px",
                  transition: "left 0.25s ease",
                }}
              />
            </Flex>
          </Flex>
        </Flex>
        <Box data-delay="0.6" className="fadein" sx={{ position: "relative" }}>
          <Image src={shippingFreights.globe1} />
          <Image
            src={shippingFreights.globe2}
            sx={{
              position: "absolute",
              left: 0,
              top: "-2px",
              width: "100%",
              height: "100%",
              opacity: switchOn ? 1 : 0,
              transition: "opacity 0.2s ease",
            }}
          />
        </Box>
      </Flex>
      <Text className="fadein" variant="caption" sx={{ fontSize: 3 }}>
        {shippingFreights.caption}
      </Text>
    </Flex>
  );
}
