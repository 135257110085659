/** @jsxImportSource theme-ui */
import { Link as RRDLink } from "react-router-dom";
import { Flex, Heading, Link } from "theme-ui";
import { LINKS, ROUTES } from "../../utils/constants";
import { fadeIn } from "../../utils/animations";
export const onWorkClick = () => {
  setTimeout(() => {
    document.getElementById("work")?.scrollIntoView({ behavior: "smooth" });
  }, 200);
};
export default function Nav() {
  return (
    <Flex
      sx={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        height: "var(--nav-height)",
        animation: `${fadeIn} 1.3s cubic-bezier(0.24, 0.74, 0.42, 0.98)`,
        animationDelay: "1s",
        animationFillMode: "both",
      }}
    >
      <Heading
        as={RRDLink}
        to={ROUTES.HOME.path}
        sx={{
          textDecoration: "none",
          color: "text",
          fontSize: 3,
          letterSpacing: "-0.96px",
          fontWeight: "bold",
        }}
      >
        dermansultan
      </Heading>
      <Flex sx={{ gap: ["15px", "34px"] }}>
        <Link variant="nav" as={RRDLink} to={ROUTES.ABOUT.path}>
          about
        </Link>
        <Link
          as={RRDLink}
          onClick={onWorkClick}
          variant="nav"
          to={`${ROUTES.HOME.path}#work`}
        >
          work
        </Link>
        <Link variant="nav" href={LINKS.RESUME} target="_blank">
          resume
        </Link>
      </Flex>
    </Flex>
  );
}
