import { Flex, Heading } from "theme-ui";
import { process } from "../../content/goodehealth";
import IconTextSquare from "../../common/IconTextSquare";
import { ReactComponent as Arrow } from "../../../../assets/goodehealth/arrow.svg";
import { Fragment } from "react";
/** @jsxImportSource theme-ui */
export default function ResearchProcess() {
  return (
    <Flex sx={{ flexDirection: "column", gap: ["40px", "84px"] }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {process.heading}
      </Heading>
      <Flex
        sx={{
          gap: ["40px", "70px"],
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          flexDirection: ["column", "row"],
        }}
      >
        {process.items.map((item, i) => (
          <Fragment key={item.text}>
            <IconTextSquare {...item} index={i} />
            {i < process.items.length - 1 && (
              <Arrow className="fadein" data-delay={(i / 3).toFixed(2)} />
            )}
          </Fragment>
        ))}
      </Flex>
    </Flex>
  );
}
