/** @jsxImportSource theme-ui */
import { Box, Flex, Heading, Image, Text } from "theme-ui";
import { features } from "../../content/trac";
export default function Features() {
  return (
    <Flex sx={{ flexDirection: "column", gap: "44px" }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {features.heading}
      </Heading>
      <Flex sx={{ gap: "51px", justifyContent: "center", flexWrap: "wrap" }}>
        {features.items.map(({ icon, caption, text }, i) => (
          <Flex
            key={caption}
            className="outlined fadein"
            data-delay={(i / 3).toFixed(2)}
            sx={{
              flexDirection: ["column", "row"],
              width: ["100%", null, "546px"],
              height: ["auto", "195px"],
              py: "36px",
              pl: "30px",
              pr: "18px",
              alignItems: [null, "center"],
              gap: "10px 0px",
            }}
          >
            <Flex
              sx={{
                alignItems: "center",
                gap: ["15px", "20px"],
                flexShrink: 0,
              }}
            >
              <Image src={icon} sx={{ width: "40px" }} />
              <Text sx={{ fontSize: 0 }}>{caption}</Text>
            </Flex>
            <Box
              sx={{
                height: "100%",
                width: "3px",
                backgroundColor: "#F3F3F3",
                ml: "34px",
                mr: "38px",
                display: ["none", "block"],
              }}
            />
            <Text
              dangerouslySetInnerHTML={{ __html: text }}
              sx={{
                fontWeight: "semibold",
                fontSize: 0,
              }}
            />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
