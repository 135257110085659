/** @jsxImportSource theme-ui */
import { Divider, Flex, Heading } from "theme-ui";
import InfoImages from "../../common/InfoImages";
import { infoImages } from "../../content/goodehealth";

export default function Solution() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        The Solution
      </Heading>
      <Divider className="fadein" margin={["55px 0", "55px 0 130px"]} />
      <InfoImages
        info={infoImages}
        imagePl="90px"
        imagePr="130px"
        textMaxWidth="670px"
      />
    </Flex>
  );
}
