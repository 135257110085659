import { Breadcrumbs } from "../components/common/Breadcrumbs";
import Spacer from "../components/common/Spacer";
import Footer from "../components/caseStudies/common/Footer";
import Hero from "../components/caseStudies/common/Hero";
import { hero } from "../components/caseStudies/content/goodehealth";
import Overview from "../components/caseStudies/goodehealth/Overview";
import Research from "../components/caseStudies/goodehealth/Research";
import DesignPhaseOne from "../components/caseStudies/goodehealth/DesignPhaseOne";
import DesignPhaseTwo from "../components/caseStudies/goodehealth/DesignPhaseTwo";
import Solution from "../components/caseStudies/goodehealth/Solution";
import Reflection from "../components/caseStudies/goodehealth/Reflection";

export default function Goodehealth() {
  return (
    <>
      <Spacer height={["40px", "125px"]} />
      <Breadcrumbs list={["Work", "Lumber", "Goodehealth Dashboard"]} />
      <Hero content={hero} />
      <Spacer height="64px" />
      <Overview />
      <Spacer height="100px" />
      <Research />
      <Spacer height="126px" />
      <DesignPhaseOne />
      <Spacer height={["120px", "173px"]} />
      <DesignPhaseTwo />
      <Spacer height={["100px", "260px"]} />
      <Solution />
      <Spacer height={["100px", "214px"]} />
      <Reflection />
      <Footer
        links={[
          { url: "/shopify-bfcm", text: "Shopify" },
          { url: "/trac", text: "Trac" },
        ]}
      />
      <Spacer height="126px" />
    </>
  );
}
