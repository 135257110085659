/** @jsxImportSource theme-ui */
import { Flex, Heading, Image, Text } from "theme-ui";
import ModalImage from "../../common/ModalImage";

export default function HeadingAndBody({
  heading,
  body,
  image,
  disableModal = false,
  imageStyles = {},
  caption,
  outerCaption,
  images,
  imgGroup,
  noGap,
  className,
}) {
  return images ? (
    <Flex
      sx={{ flexDirection: "column", gap: ["20px", "38px"] }}
      className={className}
    >
      {heading && (
        <Heading className="fadein" sx={{ fontSize: 5 }}>
          {heading}
        </Heading>
      )}
      <Flex
        sx={{
          ...(!noGap && { gap: "40px" }),
          flexWrap: ["wrap", null, "nowrap"],
          justifyContent: "center",
        }}
      >
        {images.map(
          (
            {
              src,
              caption,
              disableModal: modalDisabled = false,
              imgGroup: group,
              imageStyles = {},
            },
            i
          ) => {
            return (
              <Flex
                className="fadein"
                data-delay={(i / 3).toFixed(2)}
                key={src}
                sx={{
                  flexDirection: "column",
                  gap: "24px",
                  justifyContent: "space-between",
                  alignItems: ["center", null, "flex-start"],
                  ...imageStyles,
                }}
              >
                {modalDisabled ? (
                  <Image src={src} />
                ) : (
                  <ModalImage src={src} group={group} />
                )}
                {caption && (
                  <Text variant="caption" sx={{ fontSize: 3 }}>
                    {caption}
                  </Text>
                )}
              </Flex>
            );
          }
        )}
      </Flex>
      {outerCaption && (
        <Text variant="caption" sx={{ fontSize: 3, textAlign: "center" }}>
          {outerCaption}
        </Text>
      )}
    </Flex>
  ) : (
    <Flex
      sx={{ flexDirection: "column", gap: ["20px", "38px"] }}
      className={className}
    >
      {heading && (
        <Heading className="fadein" sx={{ fontSize: 5 }}>
          {heading}
        </Heading>
      )}
      {body && (
        <Text
          className="fadein"
          dangerouslySetInnerHTML={{ __html: body }}
          sx={{
            fontSize: 3,
            lineHeight: "162%",
            strong: { fontWeight: "bold", color: "text" },
          }}
        />
      )}
      <Flex
        sx={{ flexDirection: "column", gap: ["20px", "38px"] }}
        className="fadein"
      >
        {image &&
          (disableModal ? (
            <Image src={image} sx={{ m: "0 auto", ...imageStyles }} />
          ) : (
            <ModalImage
              group={imgGroup}
              src={image}
              sx={{ m: "0 auto", ...imageStyles }}
            />
          ))}
        {caption && (
          <Text variant="caption" sx={{ fontSize: 3, textAlign: "center" }}>
            {caption}
          </Text>
        )}
      </Flex>
    </Flex>
  );
}
