import { Box, Image } from "theme-ui";
import me from "../../assets/about/me.png";
import { ReactComponent as Bubble } from "../../assets/about/bubble.svg";
import { bubble, fadeIn, fadeInUp } from "../../utils/animations";
import { useEffect, useState } from "react";
// import frame from "../../assets/about/frame.png";
// import { ReactComponent as Pin } from "../../assets/about/pin.svg";
export default function Me({ className }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    const img = document.createElement("img");
    img.src = me;
  }, []);
  return (
    <Box
      sx={{
        position: "relative",
        width: ["auto", "100%"],
        maxWidth: [null, "413px"],
        height: "auto",
        svg: {
          position: "absolute",
          display: ["none", "block"],
          top: ["216px", "237px", "216px", "146px"],
          right: ["130px", "126px", "112px", "43px"],
          overflow: "visible",
          transform: "translateX(100%)",
          zIndex: 1,
          "#bg": {
            animation: `${bubble} 1.3s ease`,
            animationFillMode: "both",
            animationDelay: "1.3s",
          },
          "#text": {
            animation: `${fadeIn} 0.25s ease`,
            animationFillMode: "both",
            animationDelay: "1.3s",
          },
        },
      }}
      className={className}
    >
      <Bubble />
      {/* <Box
        sx={{
          position: "absolute",
          bottom: "-37px",
          right: "-24px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            svg: {
              position: "absolute",
              top: "-3px",
              left: "44%",
              transform: "translateX(-50%)",
            },
          }}
        >
          <Image src={frame} width="120" />
          <Pin />
        </Box>
      </Box> */}
      <Image
        src={me}
        sx={{
          animation: `${fadeInUp} 0.8s cubic-bezier(0.24, 0.74, 0.42, 0.98)`,
          animationPlayState: imageLoaded ? "running" : "paused",
          borderRadius: "20px",
          objectFit: "cover",
          height: "100%",
        }}
        onLoad={() => setImageLoaded(true)}
      />
    </Box>
  );
}
