/** @jsxImportSource theme-ui */

import { Button, Flex, Heading, Image, Link, Text } from "theme-ui";
import Spacer from "../common/Spacer";
import { Link as RRDLink } from "react-router-dom";
import { ReactComponent as Lock } from "../../assets/lock.svg";
import { useRef } from "react";
import { fadeIn } from "../../utils/animations";

export default function WorkTile({
  bg,
  image,
  heading,
  body,
  link,
  extraLink,
  video,
  index,
}) {
  const videoRef = useRef();
  const bgAsHex = bg.startsWith("#");
  return (
    <Flex
      className="fadein"
      data-delay={(index / 3).toFixed(2)}
      sx={{
        flexDirection: "column",
        flex: 1,
        ...(index === 0 && { minWidth: [null, "40%"] }),
        opacity: "1 !important",
        transform: "none !important",
        "&.loaded img": {
          animationPlayState: "running",
        },
      }}
    >
      <Flex
        sx={{
          flexDirection: [
            "column",
            index % 2 !== 0 ? "column" : "column-reverse",
          ],
        }}
      >
        <Flex
          as={RRDLink}
          to={link}
          onMouseEnter={() =>
            videoRef.current.play().catch((e) => console.log(e))
          }
          onMouseLeave={() => videoRef.current.pause()}
          sx={{
            position: "relative",
            borderRadius: "20px",
            overflow: "hidden",
            background: bgAsHex ? bg : `url(${bg})`,
            ":hover": {
              video: { opacity: 1 },
            },
          }}
        >
          <video
            ref={videoRef}
            src={video}
            muted
            playsInline
            preload="auto"
            loop
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: index === 0 ? "contain" : "cover",
              opacity: 0,
              transition: "opacity 0.5s ease",
              zIndex: 1,
              background: bgAsHex ? bg : `url(${bg})`,
            }}
          ></video>
          <Image
            src={image}
            sx={{
              animation: `${fadeIn} 0.5s ease`,
              animationPlayState: "paused",
              animationFillMode: "both",
              animationDelay: `${index / 2 + 0.5}s`,
            }}
          />
        </Flex>
        <Spacer height={["35px", index % 2 === 0 ? "48px" : "69px"]} />
        <Flex sx={{ flexDirection: "column" }}>
          <Heading as="strong" sx={{ fontSize: 4, fontWeight: "bold" }}>
            {heading}
          </Heading>
          <Spacer height="20px" />
          <Text
            as="p"
            sx={{ fontSize: 3, lineHeight: "191%", fontWeight: "bold" }}
          >
            {body}
          </Text>
        </Flex>
      </Flex>
      <Spacer height={["35px", "48px"]} />
      <Flex
        sx={{
          gap: "20px 35px",
          alignItems: "center",
          mt: [null, "auto"],
          flexWrap: "wrap",
        }}
      >
        <Button as={RRDLink} to={link} sx={{ gap: "10px" }}>
          View project
        </Button>
        {extraLink && (
          <Link
            sx={{
              variant: "text.caption",
              border: "none",
              lineHeight: "normal",
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
            href={extraLink.link}
          >
            {extraLink.text} <Lock />
          </Link>
        )}
      </Flex>
    </Flex>
  );
}
