import shopglobe from "../assets/home/shopglobe.svg";
import shopglobePhone from "../assets/home/shopglobe-phone.png";
import goodehealth from "../assets/home/goodehealth.png";
import trac from "../assets/home/trac.png";
import shopifyVideo from "../assets/home/globe.mp4";
import goodehealthVideo from "../assets/home/goodehealth.mp4";
import tracVideo from "../assets/home/trac.mp4";

export const ROUTES = {
  HOME: { path: "/", title: "Home" },
  ABOUT: { path: "/about", title: "About" },
  SHOPIFY_BFCM: { path: "/shopify-bfcm", title: "Shopify BFCM 22" },
  GOODEHEALTH: { path: "/goodehealth", title: "Goodehealth Dashboard" },
  TRAC: { path: "/trac", title: "Trac" },
};

export const WORK_TILES = [
  {
    bg: shopglobe,
    image: shopglobePhone,
    heading: "Shopify Globe",
    body: "Annual Black Friday Cyber Monday live globe and admin integration.",
    link: ROUTES.SHOPIFY_BFCM.path,
    // extraLink: {
    //   text: "Other Shopify Projects",
    //   link: "#",
    // },
    video: shopifyVideo,
  },
  {
    bg: "#F1F1F1",
    image: goodehealth,
    heading: "Goodehealth Dashboard",
    body: "End-to-end redesign, with the objective of boosting user retention and conversion.",
    link: ROUTES.GOODEHEALTH.path,
    video: goodehealthVideo,
  },
  {
    bg: "#4E4E57",
    image: trac,
    heading: "Trac",
    body: "A personal management application that helps students keep track of tasks, deadlines and facilitate planning of group work.",
    link: ROUTES.TRAC.path,
    video: tracVideo,
  },
];

export const LINKS = {
  LINKEDIN: "https://www.linkedin.com/in/derman-sultan/",
  GITHUB: "https://github.com/dermansultan/",
  RESUME:
    "https://drive.google.com/file/d/14IzreAWqx-epNf9E1EFd-LYWfenWPV8g/view",
  EMAIL: "mailto:abdurrehmansultan4@gmail.com",
};
