/** @jsxImportSource theme-ui */

import { Flex, Grid, Image, Text } from "theme-ui";
import { overviewCards } from "../../content/goodehealth";

export default function OverviewCards() {
  return (
    <Grid
      gap="45px 30px"
      sx={{
        gridTemplateColumns: ["auto", "1fr 1fr 1fr 1fr"],
      }}
    >
      <Flex
        className="card fadein"
        sx={{
          p: ["50px 20px", "60px 0 0 42px"],
          span: { fontWeight: "semibold" },
          position: "relative",
          gridColumnStart: [null, 1],
          gridColumnEnd: [null, 3],
          flexDirection: ["column-reverse", "row"],
          gap: ["40px", 0],
        }}
      >
        <Flex
          className="fadein"
          sx={{
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "39px",
            zIndex: 1,
          }}
        >
          <Text sx={{ fontSize: [4, 5], lineHeight: "128%", color: "strong" }}>
            {overviewCards[0].heading}
          </Text>
          <Text
            sx={{
              fontSize: [3, "2rem"],
              lineHeight: "150%",
              maxWidth: "290px",
            }}
          >
            {overviewCards[0].text}
          </Text>
        </Flex>
        <Image
          className="fadein"
          src={overviewCards[0].image}
          sx={{
            position: [null, "absolute"],
            bottom: [null, "74px"],
            right: [null, "64px"],
            width: ["100%", "332px"],
          }}
        />
      </Flex>
      <Flex
        className="card fadein"
        sx={{
          flexDirection: "column",
          p: ["40px 20px", "104px 40px 44px 40px"],
          justifyContent: "space-between",
          alignItems: "center",
          gap: "59px",
        }}
      >
        <Image className="fadein" src={overviewCards[1].image} />
        <Flex sx={{ gap: "30px", flexDirection: "column" }}>
          <Text
            className="fadein"
            sx={{
              fontSize: 5,
              fontWeight: "semibold",
              lineHeight: "128%",
              color: "strong",
            }}
          >
            {overviewCards[1].heading}
          </Text>
          <Text
            className="fadein"
            dangerouslySetInnerHTML={{ __html: overviewCards[1].text }}
            sx={{
              fontSize: 3,
              fontWeight: "semibold",
              lineHeight: "144%",
            }}
          />
        </Flex>
      </Flex>
      <Flex
        className="card fadein"
        sx={{
          p: ["40px 20px 0", "44px 43px 0px 31px"],
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Text
          className="fadein"
          sx={{
            fontSize: 5,
            fontWeight: "semibold",
            lineHeight: "128%",
            color: "strong",
          }}
        >
          {overviewCards[2].heading}
        </Text>
        <Text
          className="fadein"
          dangerouslySetInnerHTML={{ __html: overviewCards[2].text }}
          sx={{
            fontSize: 3,
            fontWeight: "semibold",
            lineHeight: "144%",
            m: "30px 0 46px",
          }}
        />
        <Image
          className="fadein"
          src={overviewCards[2].image}
          sx={{ maxWidth: "237px", m: "0 auto" }}
        />
      </Flex>
      <Flex
        className="card fadein"
        sx={{
          gridRow: [null, 2],
          gridColumnStart: [null, 1],
          gridColumnEnd: [null, 3],
          p: ["40px 20px", "39px 79px 32px 35px"],
          width: "103%",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Flex sx={{ alignItems: "center", gap: "14px" }}>
          <Image className="fadein" src={overviewCards[3].image} />
          <Text
            className="fadein"
            sx={{
              fontSize: 5,
              fontWeight: "semibold",
              lineHeight: "128%",
              color: "strong",
            }}
          >
            {overviewCards[3].heading}
          </Text>
        </Flex>
        <Text
          className="fadein"
          dangerouslySetInnerHTML={{ __html: overviewCards[3].text }}
          sx={{
            fontSize: 3,
            fontWeight: "semibold",
            lineHeight: "144%",
            strong: { fontWeight: "bold", color: "text" },
          }}
        />
      </Flex>
      <Flex
        className="card fadein"
        sx={{
          gridRow: [null, 2],
          gridColumnStart: [null, 3],
          gridColumnEnd: [null, 5],
          p: ["40px 20px", "44px 56px 32px 42px"],
          width: "97.5%",
          ml: "auto",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Flex sx={{ alignItems: "center", gap: "14px" }}>
          <Image className="fadein" src={overviewCards[4].image} />
          <Text
            className="fadein"
            sx={{
              fontSize: 5,
              fontWeight: "semibold",
              lineHeight: "128%",
              color: "strong",
            }}
          >
            {overviewCards[4].heading}
          </Text>
        </Flex>
        <Text
          className="fadein"
          dangerouslySetInnerHTML={{ __html: overviewCards[4].text }}
          sx={{
            fontSize: 3,
            fontWeight: "semibold",
            lineHeight: "144%",
            strong: { fontWeight: "bold", color: "text" },
          }}
        />
      </Flex>
    </Grid>
  );
}
