/** @jsxImportSource theme-ui */
import { Button, Flex, Grid, Heading, Image, Link, Text } from "theme-ui";
import Spacer from "../../common/Spacer";
import { useEffect } from "react";
import { fadeInUp } from "../../../utils/animations";

export default function Hero({ content }) {
  const {
    heading,
    heroImage1,
    heroImage2,
    py,
    overview: {
      demoLink,
      demoText,
      roles,
      duration,
      team,
      tools,
      demoBtnWidthOverride,
    },
  } = content;

  useEffect(() => {
    const img = document.createElement("img");
    img.src = heroImage1.path;
    if (heroImage2?.path) {
      const img2 = document.createElement("img");
      img2.src = heroImage2.path;
    }
  }, [heroImage1, heroImage2]);

  return (
    <Flex sx={{ mt: "32px", flexDirection: "column" }}>
      <Heading
        sx={{
          fontSize: [4, 6],
          fontWeight: "bold",
          animation: `${fadeInUp} 0.8s cubic-bezier(0.24, 0.74, 0.42, 0.98)`,
          animationFillMode: "both",
        }}
      >
        {heading}
      </Heading>
      <Spacer height="64px" />
      <Flex
        data-delay="0.2"
        sx={{
          backgroundColor: "#F3F3F3",
          width: "100vw",
          ml: "calc((100% - 100vw) / 2)",
          justifyContent: "center",
          py,
          px: ["20px", 0],
          minHeight: [null, "380px"],
          animation: `${fadeInUp} 0.8s cubic-bezier(0.24, 0.74, 0.42, 0.98)`,
          animationDelay: "0.4s",
          animationFillMode: "both",
        }}
      >
        <Flex
          sx={{
            width: "100%",
            justifyContent: heroImage2 ? "space-between" : "center",
            pl: heroImage2 ? ["30px", "220px"] : [0, "150px"],
            pr: [0, "150px"],
            maxWidth: "1920px",
            img: { transition: "opacity 0.5s ease" },
          }}
        >
          <Image
            src={heroImage1.path}
            width={heroImage1.width}
            sx={{ ml: heroImage1.ml }}
          />
          {heroImage2 && (
            <Image src={heroImage2.path} width={heroImage2.width} />
          )}
        </Flex>
      </Flex>
      <Spacer height="45px" />
      <Grid
        data-delay="0.4"
        gap={["10px", "12px 20px"]}
        sx={{
          gridTemplateColumns: "repeat(5, auto)",
          span: { fontSize: 3 },
          animation: `${fadeInUp} 0.8s cubic-bezier(0.24, 0.74, 0.42, 0.98)`,
          animationDelay: "0.6s",
          animationFillMode: "both",
        }}
      >
        <Text variant="caption" sx={{ gridColumn: 1 }}>
          Demo / Prototype
        </Text>
        <Text variant="caption" sx={{ gridColumn: [1, 2], mt: ["12px", 0] }}>
          Role
        </Text>
        <Text variant="caption" sx={{ gridColumn: [1, 3], mt: ["12px", 0] }}>
          Duration
        </Text>
        <Text variant="caption" sx={{ gridColumn: [1, 4], mt: ["12px", 0] }}>
          Teammates
        </Text>
        <Text variant="caption" sx={{ gridColumn: [1, 5], mt: ["12px", 0] }}>
          Tools
        </Text>
        <Button
          as={Link}
          href={demoLink}
          target="__blank"
          variant="filled"
          sx={{
            ...(demoBtnWidthOverride && { width: demoBtnWidthOverride }),
            gridColumn: 1,
            gridRow: 2,
          }}
        >
          {demoText}
        </Button>
        <List items={roles} sx={{ gridColumn: [1, 2], gridRow: [4, 2] }} />
        <Text
          variant="caption"
          sx={{ gridColumn: [1, 3], gridRow: [6, 2], color: "strong" }}
        >
          {duration}
        </Text>
        <Text
          variant="caption"
          sx={{
            gridColumn: [1, 4],
            gridRow: [8, 2],
            color: "strong",
            maxWidth: "336px",
            lineHeight: "162%",
            whiteSpace: "pre-line",
          }}
        >
          {team}
        </Text>
        <List items={tools} sx={{ gridColumn: [1, 5], gridRow: [10, 2] }} />
      </Grid>
    </Flex>
  );
}

function List({ items, className }) {
  return (
    <ul
      sx={{
        listStyle: "none",
        m: 0,
        p: 0,
        "li + li": { mt: "4px" },
      }}
      className={className}
    >
      {items.map((item) => (
        <li key={item}>
          <Text variant="caption" sx={{ color: "strong" }}>
            {item}
          </Text>
        </li>
      ))}
    </ul>
  );
}
