/** @jsxImportSource theme-ui */

import { Flex, Grid, Heading, Image, Text } from "theme-ui";
import { insights } from "../../content/goodehealth";
import Counter from "../../common/Counter";

export default function Insights() {
  return (
    <Flex sx={{ flexDirection: "column", gap: ["40px", "75px"] }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {insights.heading}
      </Heading>
      <Flex
        sx={{
          flexDirection: "column",
          gap: "58px",
          maxWidth: "1245px",
          m: "0 auto",
          width: "100%",
        }}
      >
        <Grid
          gap="32px 40px"
          sx={{ gridTemplateColumns: ["1fr", "1fr 1fr 1fr"] }}
        >
          <Text
            className="fadein"
            variant="caption"
            sx={{ fontSize: 3, ml: [0, "-10px"] }}
          >
            {insights.sections[0].heading}
          </Text>
          {insights.sections[0].items.map(
            ({ number, symbol = "", caption, icon }, i) => (
              <Flex
                className="outlined fadein"
                data-delay={(i / 3).toFixed(2)}
                key={caption}
                sx={{
                  p: "41px",
                  flexDirection: "column",
                  gap: "20px",
                  gridRow: [null, 2],
                  alignItems: "center",
                  span: { lineHeight: "normal" },
                  "&.loaded .counter": {
                    animationPlayState: "running",
                  },
                }}
              >
                <Flex sx={{ gap: "20px" }}>
                  <Image src={icon} />
                  <Counter
                    number={`${number}`}
                    suffix={symbol}
                    sx={{
                      fontSize: 6,
                      fontWeight: "semibold",
                      color: "strong",
                      span: { fontSize: "inherit" },
                    }}
                  />
                </Flex>
                <Text sx={{ fontSize: 3, fontWeight: "semibold" }}>
                  {caption}
                </Text>
              </Flex>
            )
          )}
        </Grid>
        <Grid gap="31px 48px" sx={{ gridTemplateColumns: ["1fr", "1fr 1fr"] }}>
          <Text
            className="fadein"
            variant="caption"
            sx={{ fontSize: 3, ml: "-10px" }}
          >
            {insights.sections[1].heading}
          </Text>
          {insights.sections[1].items.map(({ caption, icon }, i) => (
            <Flex
              className="outlined fadein"
              data-delay={(i / 3).toFixed(2)}
              key={caption}
              sx={{
                p: ["30px 35px", "36px 58px"],
                gap: ["25px", "38px"],
                gridRow: [null, 2],
                alignItems: "center",
                span: { lineHeight: "normal" },
                maxWidth: [null, "515px"],
                ...(i === 0 && { ml: [null, "auto"] }),
              }}
            >
              <Image src={icon} />
              <Text sx={{ fontSize: 3, fontWeight: "semibold" }}>
                {caption}
              </Text>
            </Flex>
          ))}
        </Grid>
      </Flex>
    </Flex>
  );
}
