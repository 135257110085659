/** @jsxImportSource theme-ui */
import { useContext } from "react";
import { Image } from "theme-ui";
import { ModalImageContext } from "../../Layout";

export default function ModalImage({ src, group, className, ...props }) {
  const setModalImage = useContext(ModalImageContext);
  return (
    <Image
      src={src}
      loading="lazy"
      className={className}
      sx={{
        cursor: "pointer",
      }}
      onClick={() => setModalImage({ src, group })}
      {...(group && { "data-group": group })}
      {...props}
    />
  );
}
