/** @jsxImportSource theme-ui */
import { Flex, Heading } from "theme-ui";
import Timeline from "../../common/Timeline";
import OverviewCards from "./OverviewCards";
import Spacer from "../../../common/Spacer";
import MyRole from "./MyRole";
import Success from "./Success";

export default function Overview() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Overview
      </Heading>
      <Spacer height={["55px", "100px"]} />
      <OverviewCards />
      <Spacer height={["80px", "140px"]} />
      <Timeline
        title="Design Timeline"
        sections={[
          {
            heading: "Research",
            gridColumnStart: 1,
            details: [
              {
                items: [
                  "Heuristic / Usability Evaluation",
                  "Intake and Alignment with Goodehealth",
                  "Competitor Analysis",
                ],
              },
            ],
          },
          {
            heading: "Design",
            gridColumnStart: 2,
            gridColumnEnd: 4,
            details: [
              {
                subheading: "PHASE 1",
                items: [
                  "App Structure",
                  "Site Map / User Flows",
                  "Design Exploration",
                ],
              },
              {
                subheading: "PHASE 2",
                items: ["Design System", "Feedback", "High Fidelity Prototype"],
              },
            ],
          },
          {
            heading: "Launch",
            gridColumnStart: 4,
            details: [
              {
                items: ["Product Launch", "Retrospective"],
              },
            ],
          },
        ]}
      />
      <Spacer height={["80px", "130px"]} />
      <MyRole />
      <Spacer height={["80px", "58px"]} />
      <Success />
    </Flex>
  );
}
