/** @jsxImportSource theme-ui */
import { Flex, Image, Text } from "theme-ui";

export function TitleDateLocation({
  title,
  date,
  loc,
  icon,
  className,
  ...props
}) {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: "15px",
        position: "relative",
        ...(icon && { ml: "calc(32px + 67px)" }),
        "span, strong": {
          lineHeight: "normal",
        },
      }}
      className={className}
      {...props}
    >
      {icon && (
        <Image
          src={icon}
          sx={{
            position: "absolute",
            top: "-3px",
            left: "-32px",
            transform: "translateX(-100%)",
            width: "67px",
            height: "auto",
          }}
        />
      )}
      {title && (
        <Text
          as="strong"
          sx={{
            fontSize: "1.8rem",
            fontWeight: "bold",
          }}
        >
          {title}
        </Text>
      )}
      {date && (
        <Text
          sx={{
            fontSize: "1.8rem",
            color: "strong",
          }}
        >
          {date}
        </Text>
      )}
      {loc && (
        <Text
          sx={{
            fontSize: "1.8rem",
            color: "strong",
          }}
        >
          {loc}
        </Text>
      )}
    </Flex>
  );
}
