import { Flex, Heading, Image, Text } from "theme-ui";
import { infoArch } from "../../content/goodehealth";

/** @jsxImportSource theme-ui */
export default function InfoArch() {
  return (
    <Flex sx={{ flexDirection: "column", gap: "48px" }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {infoArch.heading}
      </Heading>
      <Flex
        sx={{
          gap: "48px",
          justifyContent: "center",
          mb: "50px",
          flexDirection: ["column", "row"],
        }}
      >
        <Image
          className="fadein"
          src={infoArch.sideImg}
          sx={{ flexShrink: 0 }}
        />
        <Text
          className="fadein"
          dangerouslySetInnerHTML={{ __html: infoArch.body }}
          sx={{
            maxWidth: "1215px",
            strong: { fontWeight: "bold", color: "text" },
          }}
        />
      </Flex>
      {infoArch.images.map(({ src, caption }) => (
        <Flex
          key={caption}
          sx={{
            flexDirection: "column",
            gap: ["20px", "10px"],
            alignItems: "center",
          }}
        >
          <Image
            group="infoArch"
            className="fadein"
            src={src}
            sx={{ maxWidth: "1170px", width: "100%" }}
          />
          <Text className="fadein" variant="caption">
            {caption}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
}
