import { Flex, Heading, Image, Text } from "theme-ui";
import { userFlow } from "../../content/goodehealth";

/** @jsxImportSource theme-ui */
export default function UserFlow() {
  return (
    <Flex sx={{ flexDirection: "column", gap: "48px" }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {userFlow.heading}
      </Heading>
      <Flex
        sx={{ justifyContent: "center", gap: "50px 198px", flexWrap: "wrap" }}
      >
        <Text
          className="fadein"
          dangerouslySetInnerHTML={{ __html: userFlow.body }}
          sx={{
            maxWidth: "786px",
            fontSize: 3,
            strong: { fontWeight: "bold", color: "text" },
          }}
        />
        <Image className="fadein" src={userFlow.image} />
      </Flex>
    </Flex>
  );
}
