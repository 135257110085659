/** @jsxImportSource theme-ui */

import { Box, Divider, Flex, Grid, Heading } from "theme-ui";
import { TitleDateLocation } from "./TitleDateLocation";
import {
  achievements,
  achievementsHeading,
  experience,
  experienceHeading,
} from "./content";
import { Description } from "./Description";
import Spacer from "../common/Spacer";
import { Fragment } from "react";

export function Experience() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading
        className="fadein"
        data-delay="0.25"
        as="strong"
        sx={{ fontSize: 5, fontWeight: "semibold", mb: "53px" }}
      >
        {experienceHeading}
      </Heading>
      <Grid
        sx={{
          gridTemplateColumns: ["auto", "auto auto"],
          gridGap: ["25px", "30px 100px"],
        }}
      >
        {experience.map((item, i) => {
          return (
            <Fragment key={item.titleDateLoc.title}>
              <Box
                sx={{
                  gridRow: [null, i + 1],
                  gridColumnStart: 1,
                  gridColumnEnd: 3,
                  border: "4px solid #EFF1F2",
                  borderRadius: "20px",
                  display: ["none", "block"],
                }}
              />
              <TitleDateLocation
                {...item.titleDateLoc}
                sx={{
                  gridRow: [null, i + 1],
                  gridColumnStart: [null, 1],
                  p: [0, "58px 0px 79px 40px"],
                }}
                className="fadein"
                data-delay="0.25"
              />
              <Description
                description={item.description}
                sx={{
                  gridRow: [null, i + 1],
                  gridColumnStart: [null, 2],
                  p: [
                    "20px",
                    i !== 0 ? "70px 40px 40px 0px" : "40px 40px 40px 0px",
                  ],
                }}
                className="fadein"
                data-delay="0.25"
              />
            </Fragment>
          );
        })}
      </Grid>
      <Spacer height={["50px", "133px"]} />
      <Heading
        className="fadein"
        data-delay="0.25"
        sx={{ fontSize: 5, fontWeight: "semibold", mb: ["33px", "53px"] }}
      >
        {achievementsHeading}
      </Heading>
      <Divider
        className="fadein"
        data-delay="0.25"
        margin={["31px 0", "31px 0 63px"]}
      />
      <Grid
        sx={{
          gridTemplateColumns: ["auto", "auto auto"],
          gridGap: "35px 56px",
        }}
      >
        {achievements.map((item, i) => {
          return (
            <Fragment key={item.titleDateLoc.title}>
              <TitleDateLocation
                {...item.titleDateLoc}
                sx={{ gridRow: [null, i + 1] }}
                className="fadein"
                data-delay="0.25"
              />
              <Description
                description={item.description}
                sx={{
                  gridRow: [
                    null,
                    i === achievements.length - 1 ? i + 2 : i + 1,
                  ],
                  ...(i === achievements.length - 1 && {
                    mt: "-15px",
                    ".title": {
                      fontSize: "1.8rem",
                      color: "strong",
                      fontWeight: "semibold",
                    },
                  }),
                  p: ["20px", 0],
                }}
                className="fadein"
                data-delay="0.25"
                alt
              />
            </Fragment>
          );
        })}
      </Grid>
    </Flex>
  );
}
