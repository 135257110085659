import { keyframes } from "@emotion/react";

export const coffee = keyframes`
    0% {
        transform: translateY(0) scaleY(1);
        opacity: 0;
    }
    25% {
        transform: translateY(-1px) scaleY(1);
    }
    50% {
        transform: translateY(-4px) scaleY(2);
        opacity: 1;
    }
    100% {
        transform: translateY(-6px) scaleY(2);
        opacity: 0.1;
    }
`;

export const bubble = keyframes`
    0% {
        transform: scale(0.1, 0.2);
        opacity: 0;
    } 
    16.6% {
        transform: scale(1.2);
        opacity: 1;
    }
    33.2% {
        transform: scale(1);
    }
    49.8% {
        transform: scale(1.05);
    }
    66.4% {
        transform: scale(1);
    }
    83% {
        transform: scale(1.01);
    }
    100% {
        transform: scale(1);
    }
`;

export const fadeIn = keyframes`
    from {
        opacity: 0;
    } 
    to {
        opacity: 1;
    }
`;

export const slideUp = (height, n) => keyframes`
    0% {
        transform: translateY(0%);
    }
    25% {
        transform: translateY(5%);
    }
    75% {
        transform: translateY(calc(-100% + ${height - n * 3}px));
    }
    100% {
        transform: translateY(calc(-100% + ${height}px));
    }
`;

export const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(50px);
    } to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const slideUpReg = keyframes`
    from {
        transform: translateY(100%);
    } to {
        transform: translateY(0);
    }
`;
