/** @jsxImportSource theme-ui */
import { Divider, Flex, Heading, Text } from "theme-ui";
import { reflection } from "../../content/goodehealth";

export default function Reflection() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Reflection
      </Heading>
      <Divider className="fadein" margin="55px 0" />
      <Flex sx={{ gap: "48px", flexDirection: "column" }}>
        {reflection.map((text, i) =>
          i % 2 === 0 ? (
            <Heading
              className="fadein"
              key={text}
              sx={{ fontSize: 5, lineHeight: "normal" }}
            >
              {text}
            </Heading>
          ) : (
            <Text
              className="fadein"
              key={text}
              sx={{ fontSize: 3, lineHeight: "163%" }}
            >
              {text}
            </Text>
          )
        )}
      </Flex>
    </Flex>
  );
}
