import laptopPhone from "../../../assets/goodehealth/laptop-phone.png";
import maingoal from "../../../assets/goodehealth/maingoal.png";
import mockup from "../../../assets/goodehealth/mockup.png";
import background from "../../../assets/goodehealth/background.svg";
import alert from "../../../assets/alert-circle.svg";
import target from "../../../assets/target.svg";
import users from "../../../assets/goodehealth/users.svg";
import list from "../../../assets/goodehealth/list.svg";
import search from "../../../assets/search.svg";
import oldDash from "../../../assets/goodehealth/old-dash.png";
import subMan from "../../../assets/goodehealth/sub-man.png";
import healthScore from "../../../assets/goodehealth/health-score.png";
import biomarker from "../../../assets/goodehealth/biomarker.png";
import healthScoreOv from "../../../assets/goodehealth/health-score-ov.png";
import help from "../../../assets/help-circle.svg";
import link from "../../../assets/goodehealth/link.svg";
import plusCircle from "../../../assets/goodehealth/plus-circle.svg";
import logout from "../../../assets/goodehealth/log-out.svg";
import smartphone from "../../../assets/smartphone.svg";
import packageIcon from "../../../assets/goodehealth/package.svg";
import logos from "../../../assets/goodehealth/logos.png";
import star from "../../../assets/goodehealth/starOutline.svg";
import squares from "../../../assets/goodehealth/squares.png";
import affinity from "../../../assets/goodehealth/affinity.png";
import sitemap from "../../../assets/goodehealth/sitemap.png";
import userFlowImage from "../../../assets/goodehealth/userFlow.png";
import regFlow from "../../../assets/goodehealth/regFlow.png";
import waitingRoom from "../../../assets/goodehealth/waitingRoom.png";
import biomarkerCard from "../../../assets/goodehealth/biomarkerCard.png";
import subManFlow from "../../../assets/goodehealth/subManFlow.png";
import bioCardFlow from "../../../assets/goodehealth/bioCardFlow.png";
import earlyViz from "../../../assets/goodehealth/earlyViz.png";
import resultsHome from "../../../assets/goodehealth/resultsHome.png";
import shop from "../../../assets/goodehealth/shop.png";
import recs from "../../../assets/goodehealth/recs.png";
import subManFlowLarge from "../../../assets/goodehealth/subManFlowLarge.png";
import waitingRoomFlow from "../../../assets/goodehealth/waitingRoomFlow.png";
import award from "../../../assets/goodehealth/award.svg";
import typography from "../../../assets/goodehealth/typography.png";
import elements from "../../../assets/goodehealth/elements.png";
import elements1 from "../../../assets/goodehealth/elements1.png";
import elements2 from "../../../assets/goodehealth/elements2.png";
import nudge from "../../../assets/goodehealth/nudge.png";
import progress from "../../../assets/goodehealth/progress.svg";
import stateLoading from "../../../assets/goodehealth/stateLoading.png";
import stateConnecting from "../../../assets/goodehealth/stateConnecting.png";
import stateProcessing from "../../../assets/goodehealth/stateProcessing.svg";
import bubbles from "../../../assets/goodehealth/bubbles.png";
import mySub from "../../../assets/goodehealth/mySub.svg";
import bioCardView from "../../../assets/goodehealth/bioCardView.svg";
import scoreChart from "../../../assets/goodehealth/scoreChart.svg";
import bioLatest from "../../../assets/goodehealth/bioLatest.svg";
import bioGraph from "../../../assets/goodehealth/bioGraph.svg";
import experts from "../../../assets/goodehealth/experts.png";
import quiz from "../../../assets/goodehealth/quiz.png";
import newIn from "../../../assets/goodehealth/newIn.png";
import kitCta from "../../../assets/goodehealth/kitCta.jpg";
import infoResults from "../../../assets/goodehealth/infoResults.png";
import infoRecs from "../../../assets/goodehealth/infoRecs.png";
import infoShop from "../../../assets/goodehealth/infoShop.png";
import infoSub from "../../../assets/goodehealth/infoSub.png";
import infoProfile from "../../../assets/goodehealth/infoProfile.png";

export const hero = {
  heading: "Goodehealth Dashboard",
  heroImage1: { path: laptopPhone, width: "413px" },
  py: "100px",
  overview: {
    demoLink: "https://my.goodehealth.com/signin",
    demoText: "Live Website",
    roles: ["User Researcher", "UI Design", "Prototyping"],
    duration: "4 Months",
    team: "Lumber\nGoodehealth",
    tools: ["Figma"],
  },
};

export const overviewCards = [
  {
    heading:
      "Goodehealth's main goal is to deliver a personalized health experience.",
    text: `Educating their users on their health through their personal blood
    test kit data.`,
    image: maingoal,
  },
  {
    heading: "Background",
    text: `During the fall of 2021 Goodehealth approached Lumber with
    proposition of recreating their product technology from the ground
    up, as they were unsatisfied with the previous agencies outcome.`,
    image: background,
  },
  {
    heading: "Our Objective",
    text: `Research, strategize and design an end-to-end web experience to help
    modernize their product and reduce load to their customer experience
    team.`,
    image: mockup,
  },
  {
    heading: "The Problem",
    text: `The Goodehealth team voiced their many frustrations with their current
    digital product. Many of the underlying issues fell on unsatisfied
    customers, and their customer experience team having to fight an
    uphill battle to alleviate these issues.
    <br />
    <br />
    <strong>
      This situation creates frustration for both the customer experience
      staff and users of the product. It also represents an avoidable time
      cost to both parties.
    </strong>`,
    image: alert,
  },
  {
    heading: "The Challenge",
    text: `<strong>
    How might we redevelop Goodhealth's current product to better
    reflect what users expect from a modern health application, as well
    as address current issues with the product?
  </strong>`,
    image: target,
  },
];

export const myRole = {
  heading: "My Role",
  body: `Facilitated communication between Goodehealth and our team regarding
  design decisions. Provided our developers with UX flow-walkthroughs,
  and Design QA documents. Regularly ensuring that they were always in
  the loop with what Design was up to.`,
};

export const success = {
  heading: "What success looks like",
  items: [
    "Increase in users ordering recurring blood test kits",
    "Increase in activation rate of the blood test kit product",
    "Acquisition of more users for their partner services",
  ],
};

export const process = {
  heading: "Research Process",
  items: [
    {
      icon: users,
      text: "CX and Analytics Team Meetings",
    },
    {
      icon: list,
      text: "Competitor analysis",
    },
    {
      icon: search,
      text: "Application audit",
    },
  ],
};

export const evaluation = {
  heading: "Heuristic and Usability Evaluation",
  body: `From the beginning we wanted to alleviate the many pains Goodehealth as a client had with their product. 
  <strong>We took a broad look onwards to the entire product and found many issues that further reinforced the burden 
  upon the CX Team and Customers.</strong>`,
  image: oldDash,
  caption: "Old Dashboard Experience",
  issuesHeading:
    "The previous agency had left over boiler-plate styling for the subscription management. This resulted in many issues on the user level:",
  issues: [
    {
      heading: "Subscription Management",
      image: subMan,
      imageStyles: {
        maxWidth: "950px",
      },
      caption: "Previous Subscription Management",
      items: [
        "Not knowing where to click",
        "Inability to find key actions",
        "Lack of Proper error Prevention",
        "Management was on e-commerce site only",
      ],
    },
    {
      heading: "Results Overview",
      image: healthScore,
      imageStyles: {
        maxWidth: "1012px",
      },
      caption: "Health Score",
      items: [
        "Color for severity Ranges were confusing",
        "Blue was “better” than green",
        "Comparison slider component",
      ],
    },
    {
      heading: "Results Overview",
      image: biomarker,
      imageStyles: {
        maxWidth: "336px",
      },
      caption: "Biomarker Card View",
      items: [
        "Graph lacks clarity and the user can't interact with individual data points",
        "Biomarker sections are not distinguishable",
      ],
    },
    {
      heading: "Results Overview",
      image: healthScoreOv,
      imageStyles: {
        maxWidth: "720px",
      },
      caption: "Health Score on Overview",
      items: [
        "Rounding Error within Formula",
        "Breakdown of score hidden behind modal",
        "Color ranges were decorative rather than functional",
      ],
    },
  ],
};

export const evalTakeaways = {
  heading: "Evaluation Takeaways",
  cards: [
    {
      heading: "System status was not clear in many cases",
      text: "No visual feedback given to when the user completes actions",
      icon: alert,
    },
    {
      heading: "Confusion around scientific terms",
      text: "Scientific terms without proper labeling or accompanying information",
      icon: help,
    },
    {
      heading: "Issues with component clarity",
      text: "Text links without underlying, proper contrast, buttons without touch targeting on mobile",
      icon: link,
      styles: {
        gap: [0, "45px"],
        img: { maxWidth: "56px" },
        span: { maxWidth: "306px" },
      },
    },
  ],
};

export const insights = {
  heading: "CX and Analytics Insights",
  sections: [
    {
      heading: "By the numbers:",
      items: [
        {
          number: 40,
          symbol: "%",
          caption: "Subscribe on spot to the product",
          icon: plusCircle,
        },
        {
          number: 1500,
          caption: "Users never registered their blood kit",
          icon: logout,
        },
        {
          number: 70,
          symbol: "%",
          caption: "Users use the dashboard on mobile",
          icon: smartphone,
        },
      ],
    },
    {
      heading: "By the users (insights via CX team):",
      items: [
        {
          caption: "Trouble managing Subscriptions",
          icon: packageIcon,
        },
        {
          caption: "Confusion registering Blood Test Kit",
          icon: help,
        },
      ],
    },
  ],
};

export const compAnalysis = {
  heading: "Competitor Analysis",
  body: `There were many key solution elements we wanted to tackle with the redesign. <strong>Taking a look at other competitors from a quantitative feature comparison</strong> and how they handled these features would help us better gain insight and growth for the redesign. <strong>The framework for our analysis was as follows: strengths, opportunities and weaknesses</strong>`,
  takeaways: {
    caption: "Competitor Analysis Key Takeaways",
    image: logos,
    items: [
      {
        icon: star,
        text: "Data should be comparable",
      },
      {
        icon: star,
        text: "Data should be interactive",
      },
      {
        icon: star,
        text: "Accompanied with teachings",
      },
    ],
  },
};

export const infoArch = {
  heading: "Information Architecture",
  sideImg: squares,
  body: `As a group I proposed an affinity mapping exercise. The goal here was to create an underlying structure for the application that we could use as a base for our flows and site-maps. <strong>This exercise involved both parties and the desired outcome was to ensure we were all on the same page.</strong>`,
  images: [
    {
      src: affinity,
      caption: "Affinity Mapping Results",
    },
    {
      src: sitemap,
      caption: "Initial Sitemap based on Affinity Mapping",
    },
  ],
};

export const userFlow = {
  heading: "User Flow and Journeys",
  body: `Once as a group we were satisfied with the architecture of the dashboard we moved onto <strong>mapping out the key user flows we wanted to address with the redesign and our user journey maps.</strong>`,
  image: userFlowImage,
};

export const takeaways = {
  heading: "Takeaways",
  body: `The disconnect between the digital and physical product that needed to be addressed. <strong>We decided an avenue to solve for this was to introduce friction at key points of the user journey.</strong>`,
  images: [
    {
      caption: "Registration flow",
      image: regFlow,
      styles: { gridColumn: "1 / 3" },
    },
    {
      caption: "Onboarding / blood kit “Waiting room”",
      image: waitingRoom,
      styles: { gridColumn: "1 / 3" },
    },
    {
      caption: "Biomarker card flow",
      image: biomarkerCard,
      styles: { gridRow: "3 / 4" },
    },
    {
      caption: "Subscription management flow",
      image: subManFlow,
      styles: { gridRow: "3 / 4" },
    },
  ],
};

export const wireframes = {
  heading: "Low Fidelity Wireframes",
  sections: [
    {
      caption: "Biomarker card flow",
      image: bioCardFlow,
      body: `As we wanted to present a prototype to the stakeholders we focused on trouble areas to display within the wireframe flows. These included the <strong>registration, main navigation pages and subscription management.</strong>`,
    },
    {
      heading: "Iterations",
      caption: "Early Biomarker and Health Score Visualizations",
      image: earlyViz,
    },
    {
      caption: "Main Pages",
      body: "These are the main pages of content through out the experience. They are also directly tied with the navigation.",
      images: [
        {
          src: resultsHome,
          caption: "Results/Home",
        },
        {
          src: shop,
          caption: "Shop",
        },
        {
          src: recs,
          caption: "Recommendations",
        },
      ],
    },
    {
      caption: "Subscription Management Flow",
      image: subManFlowLarge,
    },
    {
      caption: "Onboarding / blood kit “Waiting room flow”",
      image: waitingRoomFlow,
    },
  ],
};

export const outcomes = {
  heading: "Outcomes",
  body: `After all the new information we had gained, we presented our findings to the stakeholders. <strong>We decided to create a few goals and principles we would focus our solution on.</strong>`,
  caption: "Design Principles",
  items: [
    {
      icon: award,
      text: "Educate, and empower users to explore the product",
    },
    {
      icon: award,
      text: "Bridge gap between physical and digital aspect of the product",
    },
    {
      icon: award,
      text: "Address the major CX pain points",
    },
    {
      icon: award,
      text: "Modernize the UX from a heuristic perspective",
    },
  ],
};

export const designSys = {
  heading: "Design System Co-Creation",
  body: `We were on a very tight deadline and developers needed to start building soon. This part was lead by my coworker YP as she was brought into the project for her experience creating design systems.`,
  images: [
    {
      caption: "Typography",
      src: typography,
      style: { gridColumn: ["1 / 3", null, "1 / 2"], maxHeight: "730px" },
    },
    {
      caption: "Elements",
      src: elements,
      style: { gridColumn: ["1 / 3", null, "2 / 3"], maxHeight: "730px" },
    },
    {
      caption: "Elements cont'd",
      src: elements1,
      style: { gridColumn: "1 / 3", maxHeight: "766px" },
    },
    {
      src: elements2,
      style: { gridColumn: "1 / 3", maxHeight: "769px" },
    },
  ],
};

export const goals = {
  heading: "Addressing Principles and Goals",
  body: `It was at this stage we were making sure our solution <strong>incorporated our design principles and goodehealth's business goals.</strong>`,
  badges: [
    {
      text: "Bridge the gap between physical and digital aspect of the product",
      body: `To address this issue nudges were implemented.  For starters, those waiting on their blood test kit to be analyzed by the lab, or the shipment of their superfood blend.`,
    },
    {
      text: "Modernize the UX from a heuristic perspective",
      body: `Clearer system status was addressed by anytime a change is made to a user's account a tool tip modal briefly appears. Loading screen icon to go with the brand's motif and to provide a smoother transition.`,
    },
    {
      text: "Address the major CX paint points",
    },
    {
      text: "Educate, and empower users to explore the product",
    },
  ],
  images: [
    {
      src: nudge,
      caption: "Nudge Component",
    },
    {
      src: progress,
      caption:
        "They can also monitor progress of their test kit directly from the dashboard. Previously users would had to rely on e-mail communications only.",
    },
    {
      src: stateLoading,
      caption: "Loading state, changes saved tool tip",
    },
    {
      src: stateConnecting,
      caption: "Connecting kit state",
    },
    {
      src: stateProcessing,
      caption: "System Processing Button State",
      styles: { minWidth: [null, null, "fit-content"] },
    },
    {
      src: bubbles,
      caption: "System Message Bubbles",
    },
    {
      src: mySub,
      caption: "My Subscription",
      body: `To address the pain points caused by subscription management for customers and customer experience the entire journey was reworked from the ground up. <strong>Working closely with the developers to validate my designs.</strong> Also ensuring that we could offer all the same features the users are used to and new ones.`,
    },
    {
      src: bioCardView,
      caption: "Biomarker Card View",
      body: `This was addressed by creating a system to better visualize the individual health markers.
      <br/>
      <ul>
        <li>Severity levels</li>
        <li>Total Score</li>
        <li>Varied by individual biomarker</li>
      </ul>
      <br/>
      Creating this broader scope lessens the confusion behind the scientific language previously being used.`,
      textBoxStyle: { maxWidth: "330px" },
    },
    {
      src: scoreChart,
      caption: "Health Score",
      body: `Having the final design <strong>emphasize the colour</strong> ranges, density of rings and overall score makes for a concise snapshot of health for the user to explore upon.`,
      textBoxStyle: { maxWidth: "399px" },
    },
    {
      src: bioLatest,
      caption: "Biomarker “Latest” View",
      body: `With the final design of the bars we decided to show the range the user was in with the colour of that range highlighted within the range. This made the most sense as there are some biomarkers where having a higher value is actually seen as severe. (HDL)`,
      textBoxStyle: { maxWidth: "422px" },
    },
    {
      src: bioGraph,
      caption: "Biomarker Graph View",
      body: `
      <ul>
        <li>Individual Data History</li>
        <li>Colour to communicate severity ranges</li>
        <li>Information to educate user regarding biomarker</li>
      </ul>
      <br />
      The intention here was to further educate and provide ways of actions users could improve their biomarker on.`,
      textBoxStyle: { maxWidth: "532px" },
    },
  ],
};

export const bizGoals = {
  heading: "Business Goals",
  goals: [
    "Increase In activation of rate of the blood test kit product",
    "Increase in users ordering recurring blood test kits ",
    "Acquisition of more users for their partner services (i.e. Health coaching, Stix Products)",
  ],
  details: [
    {
      caption: "Blood test kit presence",
      text: "Increase friction in registration flow and mentions of the blood kit throughout the experience",
    },
    {
      caption: "Upsells placed in strategic spots",
      text: "Introducing call out CTA's to different products offered by Goodehealth within the main experience.",
      images: [experts, quiz, newIn],
    },
  ],
  sideImg: {
    caption: "Kit activiation CTA on main navigation",
    src: kitCta,
  },
};

export const infoImages = [
  {
    heading: "Results",
    text: "The landing for the experience. This page features a summary of all the user's biomarker data. Providing extras such as the ability to print results and month to month improvements",
    image: infoResults,
  },
  {
    heading: "Recommendations",
    text: "The recommendations page provides Goodehealth customers with advice and insights of how they can improve their overall health.",
    image: infoRecs,
  },
  {
    heading: "Shop",
    text: "The shop is a transactional piece of the experience where users can browse Goodehealth's product's and directly purchase them without having to leave the dashboard",
    image: infoShop,
  },
  {
    heading: "My Subscription",
    text: "A hub for Goodehealth user's to manage their subscription. Users have the ability to check order history, and modify shipment and payment details.",
    image: infoSub,
  },
  {
    heading: "My Profile",
    text: "The shop is a transactional piece of the experience where users can browse Goodehealth's product's and directly purchase them without having to leave the dashboard",
    image: infoProfile,
  },
];

export const reflection = [
  "Cross Functional",
  "Designing for web has its own unique challenges. Working closely with developers helped me learn the limitations that arise when creating a product for the web.",
  "Cross Platform",
  "Addressing issues that were present from a physical perspective interested me the most as goodehealth's product has distinct digital and physical aspects that need to work within a single context.",
  "Onwards",
  "As the timeline was very tight for this project we weren't able to conduct formal user tests. I would love to in the future draft up a test plan in which we can hope to validate the decisions we made during this sprint.",
];
