import { Breadcrumbs } from "../components/common/Breadcrumbs";
import Spacer from "../components/common/Spacer";
import Footer from "../components/caseStudies/common/Footer";
import Hero from "../components/caseStudies/common/Hero";
import { content } from "../components/caseStudies/content/trac";
import Overview from "../components/caseStudies/trac/Overview";
import Research from "../components/caseStudies/trac/Research";
import Design from "../components/caseStudies/trac/Design";
import Solution from "../components/caseStudies/trac/Solution";
import Takeaways from "../components/caseStudies/trac/Takeaways";

export default function Trac() {
  return (
    <>
      <Spacer height={["40px", "125px"]} />
      <Breadcrumbs list={["Work", "School Projects", "Trac"]} />
      <Hero content={content.hero} />
      <Spacer height="45px" />
      <Overview />
      <Spacer height="93px" />
      <Research />
      <Spacer height={["100px", "164px"]} />
      <Design />
      <Spacer height={["100px", "257px"]} />
      <Solution />
      <Spacer height={["100px", "155px"]} />
      <Takeaways />
      <Footer
        links={[
          { url: "/shopify-bfcm", text: "Shopify" },
          { url: "/goodehealth", text: "Goodehealth" },
        ]}
      />
      <Spacer height="126px" />
    </>
  );
}
