/** @jsxImportSource theme-ui */
import { Flex, Heading, Text } from "theme-ui";
import { success } from "../../content/goodehealth";
import { ReactComponent as TrendingUp } from "../../../../assets/goodehealth/trending-up.svg";

export default function Success() {
  return (
    <Flex sx={{ flexDirection: "column", gap: ["40px", "75px"] }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {success.heading}
      </Heading>
      <Flex sx={{ flexWrap: "wrap", gap: "20px" }}>
        {success.items.map((item, i) => {
          return (
            <Flex
              className="outlined fadein"
              data-delay={(i / 3).toFixed(2)}
              key={item}
              sx={{
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
                p: "36px 20px",
                flexGrow: 1,
              }}
            >
              <TrendingUp />
              <Text
                sx={{
                  fontWeight: "bold",
                  fontSize: 3,
                  lineHeight: "163%",
                  maxWidth: "230px",
                }}
              >
                {item}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}
