import { Flex, Heading, Text } from "theme-ui";
import { outcomes } from "../../content/goodehealth";
import Spacer from "../../../common/Spacer";
import IconTextSquare from "../../common/IconTextSquare";

/** @jsxImportSource theme-ui */
export default function Outcomes() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {outcomes.heading}
      </Heading>
      <Spacer height={["40px", "90px"]} />
      <Text
        className="fadein"
        dangerouslySetInnerHTML={{ __html: outcomes.body }}
        sx={{ fontSize: 3, strong: { fontWeight: "bold", color: "text" } }}
      />
      <Spacer height={["50px", "38px"]} />
      <Text className="fadein" sx={{ fontSize: 3 }} variant="caption">
        {outcomes.caption}
      </Text>
      <Spacer height={["40px", "95px"]} />
      <Flex sx={{ gap: "60px", justifyContent: "center", flexWrap: "wrap" }}>
        {outcomes.items.map((item, i) => (
          <IconTextSquare large key={item.text} {...item} divider index={i} />
        ))}
      </Flex>
    </Flex>
  );
}
