/** @jsxImportSource theme-ui */
import { Flex, Heading, Image, Text } from "theme-ui";
import { bizGoals } from "../../content/goodehealth";
import { ReactComponent as TrendingUp } from "../../../../assets/goodehealth/trending-up.svg";

export default function BizGoals() {
  return (
    <Flex sx={{ flexDirection: "column", gap: "48px" }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {bizGoals.heading}
      </Heading>
      <Flex
        sx={{
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: ["40px", 0],
        }}
      >
        <Flex sx={{ flexDirection: "column", gap: "76px" }}>
          <Flex sx={{ flexDirection: "column", gap: "6px" }}>
            {bizGoals.goals.slice(0, 2).map((goal) => (
              <Trend key={goal}>{goal}</Trend>
            ))}
          </Flex>
          <Detail {...bizGoals.details[0]} />
        </Flex>
        <Flex
          sx={{
            flexDirection: "column",
            gap: "28px",
            alignItems: "center",
            mt: [0, "-32px"],
          }}
        >
          <Image
            className="fadein"
            src={bizGoals.sideImg.src}
            sx={{ maxWidth: "558px", width: "100%" }}
          />
          <Text className="fadein" variant="caption" sx={{ fontSize: 3 }}>
            {bizGoals.sideImg.caption}
          </Text>
        </Flex>
      </Flex>
      <Trend>{bizGoals.goals[2]}</Trend>
      <Flex sx={{ gap: "12px", flexWrap: "wrap", alignItems: "center" }}>
        <Detail {...bizGoals.details[1]} />
        {bizGoals.details[1].images.map((image, i) => (
          <Image
            className="fadein"
            group="upsells"
            data-delay={(i / 3).toFixed(2)}
            key={image}
            src={image}
            sx={{
              ...(i === 0 && { ml: [null, null, "66px"] }),
              maxWidth: "390px",
            }}
          />
        ))}
      </Flex>
    </Flex>
  );
}

function Trend({ children }) {
  return (
    <Flex className="fadein" sx={{ gap: "28px", alignItems: "center" }}>
      <TrendingUp />
      <Text
        sx={{
          fontSize: 2,
          fontWeight: "semibold",
          lineHeight: "173%",
        }}
      >
        {children}
      </Text>
    </Flex>
  );
}

function Detail({ caption, text }) {
  return (
    <Flex sx={{ flexDirection: "column", gap: "18px" }}>
      <Text className="fadein" variant="caption" sx={{ fontSize: 3 }}>
        {caption}
      </Text>
      <Text
        className="fadein"
        sx={{ fontSize: 3, lineHeight: "163%", maxWidth: "330px" }}
      >
        {text}
      </Text>
    </Flex>
  );
}
