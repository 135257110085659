/** @jsxImportSource theme-ui */
import { Divider, Flex, Heading } from "theme-ui";
import ResearchProcess from "./ResearchProcess";
import Spacer from "../../../common/Spacer";
import Evaluation from "./Evaluation";
import EvaluationTakeaways from "./EvaluationTakeaways";
import Insights from "./Insights";
import CompAnalysis from "./CompAnalysis";

export default function Research() {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5, fontWeight: "bold" }}>
        Research Findings
      </Heading>
      <Divider className="fadein" margin="55px 0 55px" />
      <ResearchProcess />
      <Spacer height={["80px", "137px"]} />
      <Evaluation />
      <Spacer height="107px" />
      <EvaluationTakeaways />
      <Spacer height="90px" />
      <Insights />
      <Spacer height="90px" />
      <CompAnalysis />
    </Flex>
  );
}
