/** @jsxImportSource theme-ui */

import { Flex, Grid, Image, Text } from "theme-ui";
import { overviewCards } from "../../content/trac";

export default function OverviewCards() {
  return (
    <Grid
      gap="45px 30px"
      sx={{
        gridTemplateColumns: ["auto", "1fr 1fr 1fr 1fr"],
      }}
    >
      <Flex
        className="card fadein"
        sx={{
          gridColumnStart: [null, 1],
          gridColumnEnd: [null, 3],
          p: ["40px 20px", "39px 79px 32px 35px"],
          width: "103%",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Flex sx={{ alignItems: "center", gap: "14px" }}>
          <Image className="fadein" src={overviewCards[0].image} />
          <Text
            className="fadein"
            sx={{
              fontSize: 5,
              fontWeight: "semibold",
              lineHeight: "128%",
              color: "strong",
            }}
          >
            {overviewCards[0].heading}
          </Text>
        </Flex>
        <Text
          className="fadein"
          dangerouslySetInnerHTML={{ __html: overviewCards[0].text }}
          sx={{
            fontSize: 3,
            fontWeight: "semibold",
            lineHeight: "144%",
          }}
        />
      </Flex>
      <Flex
        className="card fadein"
        sx={{
          gridColumnStart: [null, 3],
          gridColumnEnd: [null, 5],
          p: ["40px 20px", "44px 56px 32px 42px"],
          width: "97.5%",
          ml: "auto",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Flex sx={{ alignItems: "center", gap: "14px" }}>
          <Image className="fadein" src={overviewCards[1].image} />
          <Text
            className="fadein"
            sx={{
              fontSize: 5,
              fontWeight: "semibold",
              lineHeight: "128%",
              color: "strong",
            }}
          >
            {overviewCards[1].heading}
          </Text>
        </Flex>
        <Text
          className="fadein"
          dangerouslySetInnerHTML={{ __html: overviewCards[1].text }}
          sx={{
            fontSize: 3,
            fontWeight: "semibold",
            lineHeight: "144%",
            maxWidth: "567px",
            strong: { fontWeight: "bold", color: "text" },
          }}
        />
      </Flex>
    </Grid>
  );
}
