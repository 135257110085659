/** @jsxImportSource theme-ui */
import { Flex, Grid, Heading, Image, Text } from "theme-ui";
import { ReactComponent as Badge } from "../../../../assets/goodehealth/badge.svg";
import { goals } from "../../content/goodehealth";

export default function Goals() {
  const { images } = goals;
  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: "48px",
        strong: { fontWeight: "bold", color: "text" },
      }}
    >
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {goals.heading}
      </Heading>
      <Text
        className="fadein"
        dangerouslySetInnerHTML={{ __html: goals.body }}
        sx={{ fontSize: 3 }}
      />
      <BadgeText {...goals.badges[0]} />
      <Text className="fadein" variant="caption" sx={{ fontSize: 3 }}>
        {images[0].caption}
      </Text>
      <Image
        group="goals1"
        className="fadein"
        src={images[0].src}
        sx={{ m: "0 auto", maxWidth: "1376px", width: "100%" }}
      />
      <Text className="fadein" sx={{ textAlign: "center" }}>
        {images[1].caption}
      </Text>
      <Image group="goals1" className="fadein" src={images[1].src} />
      <BadgeText {...goals.badges[1]} />
      <Flex sx={{ gap: "58px", flexDirection: ["column", "row"] }}>
        {images.slice(2, 6).map(({ src, caption, styles = {} }) => (
          <Flex
            className="fadein"
            key={caption}
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "40px",
              ...styles,
            }}
          >
            <Image group="goals2" src={src} />
            <Text variant="caption" sx={{ fontSize: 3 }}>
              {caption}
            </Text>
          </Flex>
        ))}
      </Flex>
      <BadgeText {...goals.badges[2]} />
      <Flex
        sx={{
          width: "100%",
          maxWidth: "1210px",
          m: "0 auto",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "40px",
          flexWrap: "wrap-reverse",
        }}
      >
        <Image
          className="fadein"
          src={images[6].src}
          sx={{ m: ["0 auto", 0] }}
        />
        <Flex sx={{ flexDirection: "column", gap: "18px" }}>
          <Text className="fadein" variant="caption" sx={{ fontSize: 3 }}>
            {images[6].caption}
          </Text>
          <Text
            className="fadein"
            sx={{ fontSize: 3, maxWidth: "827px" }}
            dangerouslySetInnerHTML={{ __html: images[6].body }}
          />
        </Flex>
      </Flex>
      <BadgeText {...goals.badges[3]} />
      <Grid columns={["auto", "1fr 1fr"]} gap="30px 100px">
        {images.slice(-4).map(({ src, caption, body, textBoxStyle = {} }) => (
          <Flex
            key={caption}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: ["column", "row"],
              gap: ["40px", 0],
            }}
          >
            <Flex
              sx={{ flexDirection: "column", gap: "18px", ...textBoxStyle }}
            >
              <Text className="fadein" variant="caption" sx={{ fontSize: 3 }}>
                {caption}
              </Text>
              <Text
                className="fadein"
                sx={{ fontSize: 3, lineHeight: "163%" }}
                dangerouslySetInnerHTML={{ __html: body }}
              />
            </Flex>
            <Image group="goals3" className="fadein" src={src} />
          </Flex>
        ))}
      </Grid>
    </Flex>
  );
}

function BadgeText({ text, body }) {
  return (
    <>
      <Flex className="fadein" sx={{ gap: "28px", alignItems: "center" }}>
        <Badge />
        <Text sx={{ fontSize: 2, fontWeight: "semibold" }}>{text}</Text>
      </Flex>
      {body && (
        <Text
          className="fadein"
          dangerouslySetInnerHTML={{ __html: body }}
          sx={{ fontSize: 3, lineHeight: "163%" }}
        />
      )}
    </>
  );
}
