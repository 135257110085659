/** @jsxImportSource theme-ui */
import { Flex, Heading, Image, Text } from "theme-ui";
import { evalTakeaways } from "../../content/goodehealth";

export default function EvaluationTakeaways() {
  return (
    <Flex sx={{ flexDirection: "column", gap: ["40px", "84px"] }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {evalTakeaways.heading}
      </Heading>
      <Flex sx={{ gap: "40px", flexWrap: "wrap" }}>
        {evalTakeaways.cards.map(({ heading, text, icon, styles = {} }, i) => (
          <Flex
            className="outlined fadein"
            data-delay={(i / 3).toFixed(2)}
            key={heading}
            sx={{
              justifyContent: "flex-start",
              p: "57px 35px 35px 38px",
              gap: ["10px", "30px"],
              flex: 1,
              minWidth: "320px",
              flexDirection: ["column", "row"],
              alignItems: ["center", "flex-start"],
              ...styles,
            }}
          >
            <Image
              src={icon}
              sx={{ width: "100%", maxWidth: "63px", mb: "45px" }}
            />
            <Flex sx={{ flexDirection: "column", gap: ["10px", "28px"] }}>
              <Text
                sx={{ fontSize: 3, fontWeight: "bold", lineHeight: "normal" }}
              >
                {heading}
              </Text>
              <Text sx={{ fontWeight: "semibold" }}>{text}</Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
