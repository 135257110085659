/** @jsxImportSource theme-ui */
import { Flex, Grid, Heading, Image, Text } from "theme-ui";
import { takeaways } from "../../content/goodehealth";

export default function Takeaways() {
  return (
    <Flex sx={{ gap: "48px", flexDirection: "column" }}>
      <Heading className="fadein" sx={{ fontSize: 5 }}>
        {takeaways.heading}
      </Heading>
      <Text
        className="fadein"
        dangerouslySetInnerHTML={{ __html: takeaways.body }}
        sx={{
          strong: { fontWeight: "bold", color: "text" },
        }}
      />
      <Grid
        columns="1fr 1fr"
        gap="55px"
        sx={{ maxWidth: "1130px", m: "0 auto" }}
      >
        {takeaways.images.map(({ caption, image, styles }) => (
          <Flex
            className="fadein"
            key={caption}
            sx={{ flexDirection: "column", gap: "15px", ...styles }}
          >
            <Text variant="caption">{caption}</Text>
            <Image group="takeaways" src={image} />
          </Flex>
        ))}
      </Grid>
    </Flex>
  );
}
