/** @jsxImportSource theme-ui */
import { Fragment } from "react";
import { Flex, Text } from "theme-ui";
import { Link as RRDLink } from "react-router-dom";
import { ROUTES } from "../../utils/constants";
import { onWorkClick } from "./Nav";

export function Breadcrumbs({ list }) {
  return (
    <Flex
      as={RRDLink}
      to={`${ROUTES.HOME.path}#work`}
      onClick={onWorkClick}
      sx={{
        gap: "7px",
        span: { fontSize: [0, 3] },
        textDecoration: "none",
        flexWrap: "wrap",
      }}
    >
      {list.map((item, index) => {
        return (
          <Fragment key={item}>
            <Text variant="caption">{item}</Text>
            {index < list.length - 1 && <Text variant="caption">{">"}</Text>}
          </Fragment>
        );
      })}
    </Flex>
  );
}
